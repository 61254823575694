import React, {Component} from 'react';

class Container extends Component {

    constructor(props, context) {
        super(props, context);

    }
    
    
    render() {

        const l = this.props.l;
        const showPopupDelete = this.props.showPopupDelete;
        const showPopupLanguage = this.props.showPopupLanguage;
        const showPopupItemAdded = this.props.showPopupItemAdded;

        return(
            <div className="popup">
                <div className="popup_inner d-flex align-items-center" style={{margin: ""}}>
                    {
                        showPopupItemAdded ?
                            <div className='' style={{margin: "auto"}} >

                                <div className='kruisje text-end'>
                                    <img src="./assets/img/icons/kruisje.svg" width={25} style={{cursor: "pointer", padding: "5px 5px"}} onClick = {this.props.closePopupKruisje} />
                                </div>

                                <div className=''>
                                    <h3 style={{fontFamily: "Open Sans"}} className='text-center' >{this.props.text}</h3>
                                </div>

                                <div className='text-center' style={{marginTop: "3%"}}>
                                    <button className='colorbtn btn btn-dark' style={{marginRight: "1%", width: "36%"}} onClick={ this.props.sendPopup }>Naar winkelwagen</button>
                                    <button className='colorbtn btn btn-secondary' style={{width: "35%"}} onClick={this.props.closePopup}>Sluiten</button>
                                </div>

                            </div>

                        :
                        showPopupLanguage ?
                            <div className='' style={{margin: "auto"}}>

                                <div className='kruisje text-end'>
                                    <img src="./assets/img/icons/kruisje.svg" width={25} style={{cursor: "pointer", padding: "5px 5px"}} onClick = {this.props.closePopupKruisje} />
                                </div>
                               
                                <div className='vlag '>
                                    <img src="./assets/img/vlaggen/vlag-nl.svg" width={100} style={{cursor: "pointer", padding: "5px 5px", marginRight: "3px"}} onClick = {this.props.sendPopupNederlands} />
                                    <span>{l.DUTCH}</span>
                                </div>

                                <div className='vlag '>
                                    <img src="./assets/img/vlaggen/vlag-en.svg" width={100} style={{cursor: "pointer", padding: "5px 5px", marginRight: "3px"}} onClick = {this.props.sendPopupEngels} />
                                    <span>{l.BRITISH}</span>
                                </div>

                                <div className='vlag '>
                                    <img src="./assets/img/vlaggen/vlag-de.svg" width={100} style={{cursor: "pointer", padding: "5px 5px", marginRight: "3px"}} onClick = {this.props.sendPopupDuits} />
                                    <span>{l.GERMAN}</span>
                                </div>

                                <div className='vlag '>
                                    <img src="./assets/img/vlaggen/vlag-fr.svg" width={100} style={{cursor: "pointer", padding: "5px 5px", marginRight: "3px"}} onClick = {this.props.sendPopupFrans} />
                                    <span>{l.FRENCH}</span>
                                </div>
                               
                            </div>
                        :
                        showPopupDelete ?
                            <div className='' style={{margin: "auto"}}>

                                <div className='kruisje text-end'>
                                    <img src="./assets/img/icons/kruisje.svg" width={25} style={{cursor: "pointer", padding: "5px 5px"}} onClick = {this.props.closePopupKruisje} />
                                </div>

                                <div className=''>
                                    <h3 style={{fontFamily: "Open Sans"}} className='text-center' >{this.props.text}</h3>
                                </div>
                                
                                <div className='text-center' style={{marginTop: "3%"}}>
                                    <button className='colorbtn btn btn-dark' style={{marginRight: "1%", width: "30%"}} onClick={ this.props.sendPopup }>{l.YES}</button>
                                    <button className='colorbtn btn btn-secondary' style={{width: "30%"}} onClick={this.props.closePopup}>{l.NO}</button>
                                </div>

                            </div>
                        :
                            <div className='' style={{margin: "auto"}}>

                                <div className='kruisje text-end'>
                                    <img src="./assets/img/icons/kruisje.svg" width={25} style={{cursor: "pointer", padding: "5px 5px"}} onClick = {this.props.closePopupKruisje} />
                                </div>

                                <div className=''>
                                    <h3 style={{fontFamily: "Open Sans"}} className='text-center' >{this.props.text}</h3>
                                </div>

                                <div className='text-center' style={{marginTop: "1%"}}>
                                    <input type="text" style={{width: "60%"}} onChange={this.props.inputPopup} />
                                </div>
                                
                                <div className='text-center' style={{marginTop: "3%"}}>
                                    <button className='colorbtn btn btn-dark' style={{marginRight: "1%", width: "30%"}} onClick={ this.props.sendPopup }>{l.MODIFY}</button>
                                    <button className='colorbtn btn btn-secondary' style={{width: "30%"}} onClick={this.props.closePopup}>{l.CANCEL}</button>
                                </div>

                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default Container;