/**
 * 
 * 
 * TaopixObservable
 * 
 * 
 */
class TaopixObservable {

    //Constructor
    constructor() {
        this.observers = [];
    }

    //subscribe
    subscribe(observer) {
        this.observers.push(observer);
    }

    //unsubscribe
    unsubscribe(observer) {
        const removeIndex = this.observers.findIndex(obs => {
            return observer === obs;
        });

        if (removeIndex !== -1) {
            this.observers = this.observers.slice(removeIndex, 1);
        }
    }

    //notify
    notify(type, data) {
        if (this.observers.length > 0) {
            this.observers.forEach(observer => {
                if(observer && typeof observer.handleNotification === 'function') {
                    observer.handleNotification(type, data);
                    if(type != "tpxStartLoading") {
                        setTimeout(function() {
                            observer.handleNotification("tpxEndLoading", undefined);
                        }, 500);
                    }
            }});
        }
    }
}
let TaopixObservableInstance;
if(!TaopixObservableInstance) {
    TaopixObservableInstance = new TaopixObservable();
}

/**
 * 
 * 
 * TaopixService
 * 
 * 
 */
var kServerURL = ''; // kServerURL needs to be set to the weburl for the corresponding brand in Taopix Control Centre.

function TaopixService() {

    //Event triggered and push to observable
    function tpxEvent(type, data) {
        TaopixObservableInstance.notify(type, data);
    }

    const kGroupCode = 'TEST'; // kGroupCode needs to be the licence code that you will use
    
    var gBasketCount = 0;
    var gBasketLoaded = false;
    var gProjectListLoaded = false;
    var gProjectListCount = 0;
    var gOldBasketCount = 0;
    var gContinueShoppingMessageEnabled = 1;
    
    var kSSOOff = 0;
    var kSSOSignIn = 1;
    var kSSOAutomatic = 2;
    var kBasketInternalError = 33;
    var kBasketExpired = 34;
    var kBasketSessionExpired = 35;
    
    var gSSOEnabled = kSSOOff;
    var gSSOToken = '';

    function tpxStartLoading() {


        tpxEvent("tpxStartLoading");
    }
    
    function tpxHighLevelCreateProjectControl(pURLParams)
    {
        var paramArray = new Object();
        paramArray['id'] = pURLParams;
        paramArray['ssoenabled'] = gSSOEnabled;
    
        paramArray = tpxAddGoogleTrackingParameter(paramArray, function(pParamArray)
        {
            tpxHighLevelProcessRequest('tpxHighLevelCreateProjectControl', true, pParamArray, {});
        });
    
        return false;
    }
    
    function tpxHighLevelCreateProjectView(pJsonResponseObject)
    {
        let nameGA;
        let nameFPLC;
        let cookie = document.cookie;

        if(cookie && cookie.indexOf("_ga") > -1) {
            let newCookie = cookie.split(";");
            if(newCookie.length > 0) {
                for(let i = 0; i < newCookie.length; i++) {
                    let c = newCookie[i].trimStart();

                    if(c.split("=")[0] == "_ga") {
                        nameGA = c;
                    }
                    
                    if(c.split("=")[0] == "FPLC") {
                        nameFPLC = c;
                    }
                }
            }
        }

        if ((pJsonResponseObject.result == 0) || (pJsonResponseObject.result == -2))
        {
            let onlineDesignURL = pJsonResponseObject.designurl + "&" + nameGA + "&" + nameFPLC ;
    
            if (pJsonResponseObject.result == -2)
            {
                onlineDesignURL = onlineDesignURL;
            }
    
            window.location = onlineDesignURL;
        }
        else
        {
    
            tpxEvent("tpxHighLevelCreateProjectView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        return false;
    }
    
    function tpxHighLevelCheckUserSessionControl(pLookUpToken)
    {
        var paramArray = new Object();
        paramArray['lookuptoken'] = pLookUpToken;
        paramArray['ssoenabled'] = gSSOEnabled;
    
        tpxHighLevelProcessRequest('tpxHighLevelCheckUserSessionControl', true, paramArray, {});
    
        return false;
    }
    
    function tpxHighLevelCheckUserSessionView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result > 0)
        {
    
            tpxEvent("tpxHighLevelCreateProjectView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        return false;
    }
    
    function tpxHighLevelEditProjectControl(pProjectRef, pCanUnlock, pForceKill)
    {
        var paramArray = new Object();
        paramArray['projectref'] = pProjectRef;
        paramArray['forcekill'] = pForceKill;
        paramArray['canunlock'] = pCanUnlock;
        paramArray['ssoenabled'] = gSSOEnabled;
    
        paramArray = tpxAddGoogleTrackingParameter(paramArray, function(pParamArray)
        {
            tpxHighLevelProcessRequest('tpxHighLevelEditProjectControl', true, pParamArray, {});
        });
    
        return false;
    }
    
    function tpxHighLevelEditProjectView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result == 0)
        {
            let onlineDesignURL = pJsonResponseObject.designurl;
            window.location = onlineDesignURL;
        }
        else if (pJsonResponseObject.result == 31)
        {
    
            tpxEvent("tpxHighLevelEditProjectView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
        else
        {
            tpxHighLevelEditProjectControl(pJsonResponseObject.projectref, 1, 1);
        }
    
        return false;
    }
    
    function tpxHighLevelDuplicateProjectControl(pProjectRef, pCurrentProjectName, pNewName)
    {
        var paramArray = new Object();
        paramArray['projectref'] = pProjectRef;
    
        if (pNewName.length > 0)
        {
            paramArray['projectname'] = pNewName;
            tpxHighLevelProcessRequest('tpxHighLevelDuplicateProjectControl', false, paramArray, {});
        }
    
        return false;
    }
    
    function tpxHighLevelDuplicateProjectView(pJsonResponseObject)
    {

        if (pJsonResponseObject.result == 0)
        {
            tpxHighLevelEditProjectControl(pJsonResponseObject.projectref, 1, 1);
        }
        else
        {
        }
    
        return false;
    }
    
    function tpxHighLevelRenameProjectControl(pItemID, pProjectRef, pFromProjectList, pNewName)
    {
        var paramArray = new Object();
    
        paramArray['projectref'] = pProjectRef;
        paramArray['basketitemidtoupdate'] = pItemID;
    
        var itemIDPrefix = '';
    
        if (pFromProjectList)
        {
            itemIDPrefix = 'projectlist'
        }
    
        if (pNewName.length > 0) {
            paramArray['newname'] = pNewName;
            paramArray['fromprojectlist'] = pFromProjectList;
            tpxHighLevelProcessRequest('tpxHighLevelRenameProjectControl', false, paramArray, {});
        }
    }
    
    function tpxHighLevelRenameProjectView(pJsonResponseObject, pFromProjectList)
    {
        if (pJsonResponseObject.result == 0)
        {
    
            var itemIDPrefix = '';
    
            if (pFromProjectList)
            {
                itemIDPrefix = 'projectlist'
            }
    
            tpxEvent("tpxHighLevelRenameProjectView", {
                "pJsonResponseObject.basketitemidtoupdate": pJsonResponseObject.basketitemidtoupdate,
                "pJsonResponseObject.newprojectname": pJsonResponseObject.newprojectname,
            })
        }
        else
        {
    
            tpxEvent("tpxHighLevelRenameProjectView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        return false;
    }
    
    function tpxHighLevelDeleteProjectControl(pItemID, pProjectRef, pProjectName, pCanUnlock, pForceKill)
    {
        var paramArray = new Object();
        paramArray['projectref'] = pProjectRef;
        paramArray['forcekill'] = pForceKill;
        paramArray['canunlock'] = pCanUnlock;
        paramArray['itemtoremoveid'] = pItemID;
    
        tpxHighLevelProcessRequest('tpxHighLevelDeleteProjectControl', false, paramArray, {});
    }
    
    function tpxHighLevelDeleteProjectView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result == 0)
        {
    
            tpxEvent("tpxHighLevelDeleteProjectView", {
                "pJsonResponseObject.itemtoremoveid": pJsonResponseObject.itemtoremoveid,
            })
        }
        else
        {
    
            tpxEvent("tpxHighLevelDeleteProjectView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject.resultmessage,
            })
        }
    
        tpxCreateBasketCountCookie();
    
        return false;
    }
    
    function tpxHighLevelKeepProjectView(pJsonResponseObject)
    {
        if (! pJsonResponseObject.status)
        {
            return;
        }
    
        tpxEvent("tpxHighLevelKeepProjectView", {
            "pJsonResponseObject.projectref": pJsonResponseObject.projectref,
        })
    
        return;
    }
    
    function tpxHighLevelBasketInitialise()
    {
        var mawIDLookup = tpxReadCookie('mawhluid');
        var lookUpToken = tpxGetUrlVar('mawbt');
        var basketRef = '';
        var basketRefLookUpValue = tpxReadCookie('mawebhlbr');
        var hlCreateID = tpxGetUrlVar('mawebhlcreate');
        var ssoAction = '';
        var ssoActionParam = [];
        var ssoKey = tpxGetUrlVar('ssokey');
        var gwmParam = tpxGetUrlVar('gwm');
        var languageParam = tpxGetUrlVar('l');
        var gdParam = tpxGetUrlVar('gd');
        var wmoParam = tpxGetUrlVar('wmo');
        var wmolsParam = tpxGetUrlVar('wmols');
        var wmossParam = tpxGetUrlVar('wmoss');
        var wmosdParam = tpxGetUrlVar("wmosd");
        var uioParam = tpxGetUrlVar('uio');
        gOldBasketCount = tpxReadCookie('mawhlbc');
    
        gOldBasketCount = gOldBasketCount ? gOldBasketCount : 0;
    
        if (languageParam == '')
        {
            languageParam = tpxGetBrowserLocale();
        }
    
        // if the unique high level cookie has not been created then we must create it.
        if ((mawIDLookup == null) || (mawIDLookup == ''))
        {
            tpxCreateMAWHLUIDCookie();
        }
    
           // Single item workflow has been invoked with Multi Line Basket enabled on the brand
           // we need to take the user straight into the project
        if (hlCreateID != '')
        {
            var URLParams = 'id=' + hlCreateID;
    
            URLParams += (gwmParam != '') ? '&gwm=' + gwmParam : '';
            URLParams += (languageParam != '') ? '&l=' + languageParam : '';
            URLParams += (gdParam != '') ? '&gd=' + gdParam : '';
            URLParams += (wmoParam != '') ? '&wmo=' + wmoParam : '';
            URLParams += (wmolsParam != '') ? '&wmols=' + wmolsParam : '';
            URLParams += (wmossParam != '') ? '&wmoss=' + wmossParam : '';
            URLParams += (wmosdParam != '') ? '&wmosd=' + wmosdParam : '';
            URLParams += (uioParam != '') ? '&uio=' + uioParam : '';
    
            // Add the custom parameters that have been passed by url.
            URLParams += tpxCustomParams();
    
            tpxHighLevelCreateProjectControl(URLParams);
            return false;
        }
    
           // tpxHighLevelBasketLocalise();
    
           // check to see if we have a basket cookie and if we have use the cookie value for the basketref.
        if ((basketRefLookUpValue != null) && (basketRefLookUpValue != ''))
        {
            basketRef = basketRefLookUpValue;
        }
    
        if ((gSSOEnabled != kSSOOff) && (ssoKey != ''))
        {
            var cookieData = tpxReadCookie('mawssoa');
    
            if (cookieData != null)
            {
                var cookieDataArray = cookieData.split('|');
    
                if (cookieDataArray.length == 2)
                {
                    ssoAction = cookieDataArray[0];
    
                    if (cookieDataArray[1] != '')
                    {
                        ssoActionParam = JSON.parse(cookieDataArray[1]);
                    }
                }
            }
        }
    
        tpxDeleteCookie("mawssoa");
    
        if ((ssoAction != '') && (ssoKey != ''))
        {
            if ((ssoAction == 'tpxHighLevelSignInInitControl') && (gSSOEnabled == kSSOAutomatic))
            {
                ssoAction = 'tpxHighLevelSignInInitControl2';
            }
    
            tpxHighLevelProcessRequest(ssoAction, false, ssoActionParam, {'sso': 2, 'ssokey': ssoKey});
        }
        else
        {
            // if the session cookie has a legitimate value && we have been redirected back to the product selector,
            // or we have a basket ref and the session cookie is still not a legitimate session ref
            // then we must check to see if the session is still active
            if ((lookUpToken != '') || ((basketRef != '') || (tpxGetUrlVar('odlo') == 1) || (gSSOEnabled == kSSOAutomatic)))
            {
                if (tpxGetUrlVar('odlo') == 1)
                {
                    tpxDeleteCookie("mawebhlbr");
                }
    
                tpxHighLevelCheckUserSessionControl(lookUpToken);
            }
        }
    }
    
    function tpxHighLevelLoggedInStatusCallBack(pIsSignedIn)
    {
        tpxEvent("tpxHighLevelLoggedInStatusCallBack", {
            "pIsSignedIn": pIsSignedIn,
        })
    
        var newBasketCount = gBasketCount;
    
        if ((newBasketCount > gOldBasketCount) && (!tpxGetUrlVar('mawbt')) && (gContinueShoppingMessageEnabled))
        {
            tpxEvent("tpxAddedToCart", {
                newBasketCount: newBasketCount, 
                gOldBasketCount: parseInt(gOldBasketCount),
            })
        }
    }
    
    function tpxHighLevelLogoutControl()
    {
        tpxHighLevelProcessRequest('tpxHighLevelLogoutControl', false, {}, {});
    
        return false;
    }
    
    function tpxHighLevelLogoutView(pJsonResponseObject)
    {
        if ((pJsonResponseObject.result == 0) || (pJsonResponseObject.result == -2))
        {
            if ((pJsonResponseObject.result == -2) && (pJsonResponseObject.ssoredirect != ''))
            {
                document.location = pJsonResponseObject.ssoredirect;
            }
            else
            {
                tpxHighLevelLoggedInStatusCallBack(0);
            }
        }
    
        return false;
    }
    
    function tpxHighLevelGetBasketContentsControl()
    {
        tpxHighLevelProcessRequest('tpxHighLevelGetBasketContentsControl', false, {}, {});
    
        return false;
    }
    
    function tpxHighLevelGetBasketContentsView(pJsonResponseObject)
    {
        var responseEmpty = tpxIsEmpty(pJsonResponseObject);
    
        if ((gBasketCount > 0) && (! responseEmpty))
        {
            var basketItems = pJsonResponseObject.items;
    
            tpxEvent("tpxHighLevelGetBasketContentsView", {
                gBasketCount: gBasketCount,
                basketItems: basketItems
            })
        }
        else if (gBasketCount === 0)
        {
    
            tpxEvent("tpxHighLevelGetBasketContentsView", {
                gBasketCount: gBasketCount
            })
        }
    
        return false;
    }
    
    function tpxHighLevelGetProjectListControl()

    {
        tpxHighLevelProcessRequest('tpxHighLevelGetProjectListControl', false, {}, {});
    
        return false;
    }
    
    function tpxHighLevelGetProjectListView(pJsonResponseObject)
    {
        var responseEmpty = tpxIsEmpty(pJsonResponseObject);
    
        if ((gProjectListCount > 0) && (! responseEmpty))
        {
            var projectItems = pJsonResponseObject.items;
    
            tpxEvent("tpxHighLevelGetProjectListView", {
                projectItems: projectItems
            })
        }
        else if(gProjectListCount === 0)
        {
            tpxEvent("tpxHighLevelGetProjectListView", {
                gProjectListCount: gProjectListCount
            })
        }
    }
    
    function tpxHighLevelRemoveItemFromBasketControl(pItemID, pProjectRef, pForceKill)
    {
        var paramArray = new Object();
        paramArray['itemtoremoveid'] = pItemID;
        paramArray['projectref'] = pProjectRef;
        paramArray['forcekill'] = pForceKill;
    
        tpxHighLevelProcessRequest('tpxHighLevelRemoveItemFromBasketControl', false, paramArray, {});
    
        return false;
    }
    
    function tpxHighLevelRemoveItemFromBasketView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result == 0)
        {
    
            tpxEvent("tpxHighLevelRemoveItemFromBasketView", {
                gBasketCount: gBasketCount,
            })
        }
        else
        {
    
            tpxEvent("tpxHighLevelRemoveItemFromBasketView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        tpxCreateBasketCountCookie();
    
        return false;
    }
    
    function tpxHighLevelEmptyBasketControl()
    {
        var paramArray = new Object();
        paramArray['forcekill'] = 1;
        tpxHighLevelProcessRequest('tpxHighLevelEmptyBasketControl', false, paramArray, {});
    
        return false;
    }
    
    function tpxHighLevelEmptyBasketView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result == 0)
        {
            tpxEvent("tpxHighLevelEmptyBasketView", {
                gBasketCount: gBasketCount,
            })
        }
        else
        {
    
            tpxEvent("tpxHighLevelEmptyBasketView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        return false;
    }
    
    function tpxHighLevelCheckoutControl()
    {
        var paramArray = new Object();
        paramArray['ssoenabled'] = gSSOEnabled;
    
        tpxHighLevelProcessRequest('tpxHighLevelCheckoutControl', true, paramArray, {});
    
        return false;
    }
    
    function tpxHighLevelCheckoutView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result == 0)
        {
            let shoppingCartURL = pJsonResponseObject.shoppingcarturl;
    
            tpxEvent("tpxHighLevelCheckoutView", {
                "pJsonResponseObject.shoppingcarturl": pJsonResponseObject?.shoppingcarturl,
                "shoppingCartURL": shoppingCartURL
            })
        }
        else
        {
    
            tpxEvent("tpxHighLevelCheckoutView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        return false;
    }
    
    function tpxHighLevelSignInInitControl()
    {
        var paramArray = new Object();
        paramArray['groupcode'] = kGroupCode;
        paramArray['ssoenabled'] = gSSOEnabled;
    
        tpxHighLevelProcessRequest('tpxHighLevelSignInInitControl', true, paramArray, {});
    
        return false;
    }
    
    function tpxHighLevelSignInInitView(pJsonResponseObject)
    {
        if ((pJsonResponseObject.result == 0) || (pJsonResponseObject.result == -2))
        {
            var signInURL = pJsonResponseObject.signinurl;
            if (signInURL != '')
            {
                window.location = signInURL;
            }
            else
            {
                var isLoggedInLookUpValue = tpxReadCookie('mawuli');
                tpxHighLevelLoggedInStatusCallBack(isLoggedInLookUpValue);
            }
        }
        else
        {
            tpxEvent("tpxHighLevelSignInInitView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
        return false;
    }
    
    function tpxHighLevelRegisterInitControl()
    {
        var paramArray = new Object();
        paramArray['groupcode'] = kGroupCode;
        tpxHighLevelProcessRequest('tpxHighLevelRegisterInitControl', false, paramArray, {});
    
        return false;
    }
    
    function tpxHighLevelRegisterInitView(pJsonResponseObject)
    {
    
        if (pJsonResponseObject.result == 0)
        {
            tpxEvent("tpxHighLevelRegisterInitView", {
                "pJsonResponseObject.signinurl": pJsonResponseObject?.signinurl,
            });
    
        }
        else
        {
            tpxEvent("tpxHighLevelRegisterInitView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            });
        }
    
        return false;
    }
    
    function tpxHighLevelMyAccountInitControl()
    {
        tpxHighLevelProcessRequest('tpxHighLevelMyAccountInitControl', false, {}, {});
    
        return false;
    }
    
    function tpxHighLevelMyAccountInitView(pJsonResponseObject)
    {
        if (pJsonResponseObject.result == 0)
        {
            let myAccountURL = pJsonResponseObject.myaccounturl;
    
            tpxEvent("tpxHighLevelMyAccountInitView", {
                "pJsonResponseObject.myaccounturl": pJsonResponseObject?.myaccounturl,
                "myAccountURL": myAccountURL
            })
        }
        else
        {
    
            tpxEvent("tpxHighLevelMyAccountInitView", {
                "pJsonResponseObject.resultmessage": pJsonResponseObject?.resultmessage,
            })
        }
    
        return false;
    }
    
    function tpxBasketOnClick()
    {
        tpxHighLevelGetBasketContentsControl();
    }
    
    function tpxMyProjectsOnClick()
    {
        tpxHighLevelGetProjectListControl();
    }
    
    function tpxHighLevelProcessRequest(pRequestFunction, pSetCookie, pParams, pSSOParams)
    {
        var serverPage = '';
        var fsAction = '';
        var callback = '';
        var requestMethod = 'POST';
        var performRequest = true;
        var theDate = new Date();
        var timestamp = Math.round((theDate.getTime()) / 1000);
        var basketRef = '';
        var mawID = 0;
        var language = tpxGetBrowserLocale();
        var mawIDLookup = tpxReadCookie('mawhluid');
        var basketRefLookUpValue = tpxReadCookie('mawebhlbr');
    
        // if the unique high level cookie has not been created then we must create it.
        if ((mawIDLookup != null) || (mawIDLookup != ''))
        {
            mawID = mawIDLookup;
        }
    
        if ((basketRefLookUpValue != null) && (basketRefLookUpValue != ''))
        {
            basketRef = basketRefLookUpValue;
        }
    
        if ((gSSOEnabled != kSSOOff) && (pSetCookie))
        {
            var cookieData = pRequestFunction + "|" + JSON.stringify(pParams);
            tpxCreateCookie("mawssoa", cookieData, "Fri, 31 Dec 9999 23:59:59 GMT");
        }
    
        switch (pRequestFunction)
        {
            case 'tpxHighLevelCheckUserSessionControl':
                fsAction = '?fsaction=OnlineAPI.checkUserSession';
                callback = tpxHighLevelCheckUserSessionView;
            break;
            case 'tpxHighLevelCreateProjectControl':
                requestMethod = 'GET';
                callback = tpxHighLevelCreateProjectView;
                fsAction = '?fsaction=OnlineAPI.createProject3&' + pParams['id'] + '&ssoenabled=' + pParams['ssoenabled'] + '&mawebhlbr=' + basketRef + '&prtz=' + timestamp + '&browserlocale=' + language;
    
                // add the _ga google parameter is present
                if (pParams['_ga'])
                {
                    fsAction += "&_ga=" + pParams['_ga'];
                }
    
            break;
            case 'tpxHighLevelEditProjectControl':
                fsAction = '?fsaction=OnlineAPI.hlEditProject';
                callback = tpxHighLevelEditProjectView;
            break;
            case 'tpxHighLevelDuplicateProjectControl':
                fsAction = '?fsaction=OnlineAPI.hlDuplicateProject';
                callback = tpxHighLevelDuplicateProjectView;
            break;
            case 'tpxHighLevelRenameProjectControl':
                fsAction = '?fsaction=OnlineAPI.hlRenameProject';
                callback = tpxHighLevelRenameProjectView;
            break;
            case 'tpxHighLevelDeleteProjectControl':
                fsAction = '?fsaction=OnlineAPI.hlDeleteProject';
                callback = tpxHighLevelDeleteProjectView;
            break;
            case 'tpxHighLevelGetBasketContentsControl':
                fsAction = '?fsaction=OnlineAPI.basketInit';
                callback = tpxHighLevelGetBasketContentsView;
    
                if (gBasketLoaded)
                {
                    performRequest = false
                }
    
            break;
            case 'tpxHighLevelGetProjectListControl':
                fsAction = '?fsaction=OnlineAPI.hlViewProjectsList';
                callback = tpxHighLevelGetProjectListView;
    
                if (gProjectListLoaded)
                {
                    performRequest = false
                }
    
            break;
            case 'tpxHighLevelEmptyBasketControl':
                fsAction = '?fsaction=OnlineAPI.emptyBasket';
                callback = tpxHighLevelEmptyBasketView;
            break;
            case 'tpxHighLevelRemoveItemFromBasketControl':
                fsAction = '?fsaction=OnlineAPI.removeItemFromBasket';
                callback = tpxHighLevelRemoveItemFromBasketView;
            break;
            case 'tpxHighLevelCheckoutControl':
                fsAction = '?fsaction=OnlineAPI.checkout';
                callback = tpxHighLevelCheckoutView;
            break;
            case 'tpxHighLevelSignInInitControl':
            case 'tpxHighLevelSignInInitControl2':
                fsAction = '?fsaction=OnlineAPI.signInInit';
    
                if (pRequestFunction == 'tpxHighLevelSignInInitControl')
                {
                    callback = tpxHighLevelSignInInitView;
                }
                else
                {
                    callback = tpxHighLevelCheckUserSessionView;
                }
    
            break;
            case 'tpxHighLevelRegisterInitControl':
                fsAction = '?fsaction=OnlineAPI.registerInit';
                callback = tpxHighLevelRegisterInitView;
            break;
            case 'tpxHighLevelMyAccountInitControl':
                fsAction = '?fsaction=OnlineAPI.myAccountInit';
                callback = tpxHighLevelMyAccountInitView;
            break;
            case 'tpxHighLevelLogoutControl':
                fsAction = '?fsaction=OnlineAPI.hlLogout';
                callback = tpxHighLevelLogoutView;
            break;
            // case 'tpxHighLevelShareProjectControl':
            //     fsAction = '?fsaction=OnlineAPI.highLevelShareProject';
            //     callback = tpxHighLevelShareProjectView;
            // break;
            case 'tpxHighLevelKeepProjectControl':
                fsAction = '?fsaction=OnlineAPI.highLevelKeepProject';
                callback = tpxHighLevelKeepProjectView;
            break;
            default:
                break;
        }
    
        serverPage = kServerURL + fsAction;
    
        for (var key in pSSOParams)
        {
            serverPage = tpxAddGETParam(serverPage, key, encodeURIComponent(pSSOParams[key]));
        }
    
        if (performRequest)
        {
            /* get an XMLHttpRequest object for use */
            /* make xmlhttp local so we can run simlutaneous requests */
            var xmlhttp = tpxGetXMLHTTP();
    
            if (requestMethod == 'POST')
            {
                pParams['mawebhluid'] = mawID;
                pParams['mawebhlbr'] = basketRef;
                pParams['prtz'] = timestamp;
                pParams['browserlocale'] = tpxGetBrowserLocale();
                pParams['ssotoken'] = gSSOToken;

                pParams['gaTest'] = "1000000"
    
                xmlhttp.open('POST', serverPage, false);
                xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    
                var postParams = '';
    
                for (var key in pParams)
                {
                    postParams += '&' + key + '=' + encodeURIComponent(pParams[key]);
                }
            }
            else
            {
                serverPage = tpxAddGETParam(serverPage, 'dummy', new Date().getTime());
                serverPage = tpxAddGETParam(serverPage, 'ssotoken', gSSOToken);
    
                xmlhttp.open("GET", serverPage, true);
            }
    
            xmlhttp.onreadystatechange = function()
            {
                if ((xmlhttp.readyState == 4) && (xmlhttp.status == 200))
                {
                    var responseObj = JSON.parse(xmlhttp.responseText);

                    // if ((responseObj.result == kBasketInternalError) || (responseObj.result == kBasketExpired) || (responseObj.result == kBasketSessionExpired))
                    //! DO NOT TRIGGER ON BASKET EXPIRED, THIS ALSO TRIGGERS ON AN EMPTY BASKET
                    if ((responseObj.result == kBasketInternalError) || (responseObj.result == kBasketSessionExpired))
                    {
                        gBasketCount = 0;

                        tpxDeleteCookie("mawuli");
                        tpxDeleteCookie("mawebhlbr");
    
                        if (pRequestFunction == 'tpxHighLevelCreateProjectControl')
                        {
                            // if the basket or session has expired then only for a create project action we must try to replay the createProject.
                            // as we have deleted the basket cookie a new cookie will be generated when the action is replayed.
                            tpxHighLevelProcessRequest('tpxHighLevelCreateProjectControl', true, pParams, {});
                            return false;
                        }
                        else
                        {
                            tpxHighLevelLoggedInStatusCallBack(0);
                        }
                    }
    
                    switch (pRequestFunction)
                    {
                        case 'tpxHighLevelSignInInitControl':
    
                            if (responseObj.result == -2)
                            {
                                // set basketcookie based of the token
                                basketRef = responseObj.basketref;
                                let basketCookieExpiryTime = responseObj.basketcookieexpirytime;
                                let userCookieExpiryTime = responseObj.usercookieexpirytime;
    
                                var date = new Date();
                                date.setTime(basketCookieExpiryTime * 1000);
    
                                tpxCreateCookie("mawebhlbr", basketRef, date.toGMTString());
    
                                var date = new Date();
                                date.setTime(userCookieExpiryTime * 1000);
    
                                tpxCreateCookie("mawuli", 1, date.toGMTString());
    
                                gSSOToken = responseObj.ssotoken;
    
                                gBasketCount = 0;
                                gBasketLoaded = false;
                                gProjectListLoaded = false;
                                gProjectListCount = 0;
    
                            }
                        break;
                        case 'tpxHighLevelSignInInitControl2':
                        case 'tpxHighLevelCheckUserSessionControl':
                            if (responseObj.result == 0)
                            {
                                // set basketcookie based of the token
                                basketRef = responseObj.basketref;
                                let basketCookieExpiryTime = responseObj.basketcookieexpirytime;
                                let userCookieExpiryTime = responseObj.usercookieexpirytime;
                                gContinueShoppingMessageEnabled = responseObj.continueshoppingmessageenabled;
                                gContinueShoppingMessageEnabled = parseInt(gContinueShoppingMessageEnabled);
    
                                if (isNaN(gContinueShoppingMessageEnabled))
                                {
                                    gContinueShoppingMessageEnabled = 1;
                                }
    
                                var date = new Date();
                                date.setTime(basketCookieExpiryTime * 1000);
    
                                tpxCreateCookie("mawebhlbr", basketRef, date.toGMTString());
    
                                var date = new Date();
                                date.setTime(userCookieExpiryTime * 1000);
    
                                tpxCreateCookie("mawuli", 1, date.toGMTString());
    
                                gSSOToken = responseObj.ssotoken;
    
                                gBasketCount = responseObj.basketcount;
                                tpxCreateBasketCountCookie();
    
                                tpxHighLevelLoggedInStatusCallBack(1);
                            }
                            else if ((responseObj.result == -1) || (responseObj.result > 0))
                            {
                                tpxDeleteCookie("mawuli");
    
                                if (((tpxGetUrlVar('odlo') == 1) && (responseObj.result == -1)) || (responseObj.result > 0) || ((responseObj.result == -1) && (gSSOEnabled != kSSOOff)))
                                {
                                    tpxDeleteCookie("mawebhlbr");
                                }
                            }
                            else if (responseObj.result == -2)
                            {
                                document.location = responseObj.ssoredirect;
                            }
    
                        break;
                        case 'tpxHighLevelCreateProjectControl':
    
                            if (responseObj.result == 0)
                            {
                                basketRef = responseObj.basketref;
                                let cookieExpiryTime = responseObj.cookieexpirytime;
    
                                var date = new Date();
                                date.setTime(cookieExpiryTime * 1000);
    
                                tpxCreateCookie("mawebhlbr", basketRef, date.toGMTString());
                            }
    
                            break;
                        case 'tpxHighLevelGetBasketContentsControl':
                                gBasketLoaded = true;
                                gBasketCount = responseObj.basketcount;
    
                            break;
                        case 'tpxHighLevelGetProjectListControl':
    
                                if (responseObj.result != 0)
                                {
                                    basketRef = responseObj.basketref;
                                    let cookieExpiryTime = responseObj.cookieexpirytime;
    
                                    var date = new Date();
                                    date.setTime(cookieExpiryTime * 1000);
    
                                    tpxCreateCookie("mawebhlbr", basketRef, date.toGMTString());
                                    tpxDeleteCookie("mawuli");
                                }
    
                                gProjectListLoaded = true;
                                gProjectListCount = responseObj.basketcount;
    
                            break;
                        case 'tpxHighLevelLogoutControl':
    
                            tpxDeleteCookie("mawuli");
                            tpxDeleteCookie("mawebhlbr");
    
                            gBasketCount = 0;
                            gProjectListCount = 0;
    
                        break;
                        case 'tpxHighLevelCheckoutControl':
                            if (responseObj.result != 0)
                            {

                                //! DO NOT TRIGGER ON BASKET EXPIRED, THIS ALSO TRIGGERS ON AN EMPTY BASKET
                                //tpxDeleteCookie("mawuli");
                                //tpxDeleteCookie("mawebhlbr");

                                gBasketCount = 0;
                                gProjectListCount = 0;
                            }
    
                            break;
                        case 'tpxHighLevelEditProjectControl':
    
                            if ((responseObj.result != 0) && (responseObj.ssoerror))
                            {
                                tpxDeleteCookie("mawuli");
                                tpxDeleteCookie("mawebhlbr");
    
                                gBasketCount = 0;
                                gProjectListCount = 0;
                            }
    
                            break;
                        case 'tpxHighLevelRemoveItemFromBasketControl':
                            if ((gBasketCount > 0) && (responseObj.result === 0))
                            {
                                gBasketCount--;
                            }
    
                            gProjectListLoaded = false;
    
                        break;
                        case 'tpxHighLevelEmptyBasketControl':
                            gBasketCount = 0;
                            gProjectListLoaded = false;
                        break;
                        default:
                            break;
                    }
    
                    if (pRequestFunction == 'tpxHighLevelRenameProjectControl')
                    {
                        callback(responseObj, pParams['fromprojectlist']);
                    }
                    else
                    {
                        callback(responseObj);
                    }
                }
            };
    
            if (requestMethod == 'POST')
            {
                xmlhttp.send(postParams);
            }
            else
            {
                xmlhttp.send(null);
            }
        }
        else
        {
            let responseObj = {};
            callback(responseObj);
        }
    }
    
    function tpxGetBrowserLocale()
    {
        // determine the browser locale either from the browser
        var browserLanguage = 'en';
    
        if (navigator.userLanguage)
        {
            browserLanguage = navigator.userLanguage;
        }
        else if (navigator.language)
        {
            browserLanguage = navigator.language;
        }
    
        // get the first (main) browser language
        var browserLanguageArray = browserLanguage.split(",");
        browserLanguage = browserLanguageArray[0].toLowerCase();
    
        switch (browserLanguage)
        {
            case 'zh-tw':
            case 'zh-cn':
            {
                break;
            }
            default:
            {
                if (browserLanguage.length > 2)
                {
                    browserLanguage = browserLanguage.substring(0, 2);
                }
                break;
            }
        }
    
        return browserLanguage.replace('-', '_');
    }
    
    function tpxGetUrlVar(key)
    {
        var result = new RegExp(key + "=([^&]*)", "i").exec(window.location.search);
        return result && unescape(result[1]) || "";
    }
    
    function tpxGetLocaleString(pLocalizedString)
    {
        // return the correct language string
        var result = '';
        var firstAvailable = '';
        var defaultLanguage = '';
    
        var locale = tpxGetBrowserLocale();
    
        var locale2 = locale.substring(0, 2);
    
        var localizedStringList = pLocalizedString.split('<p>');
        var localizedCount = localizedStringList.length;
    
        for (var i = 0; i < localizedCount; i++)
        {
            // split each language item into its code and name
            var charPos = localizedStringList[i].indexOf(' ');
            var localizedItemCode = localizedStringList[i].substring(0, charPos);
            var localizedItemCode2 = localizedItemCode.substring(0, 2);
            var localizedItemString = localizedStringList[i].substring(charPos + 1);
    
            if ((firstAvailable == '') && (localizedItemString != ''))
            {
                firstAvailable = localizedItemString;
            }
    
            if (localizedItemCode == 'en')
            {
                defaultLanguage = localizedItemString;
            }
    
            if ((result == '') && (localizedItemCode2 == 'en'))
            {
                result = localizedItemString;
            }
            else if ((localizedItemCode2 == locale2) && (locale2 != 'zh'))
            {
                result = localizedItemString;
            }
            else if (localizedItemCode == locale)
            {
                result = localizedItemString;
                break;
            }
            else if (localizedItemCode == locale2)
            {
                result = localizedItemString;
                break;
            }
        }
    
        if (result == '')
        {
            if (defaultLanguage != '')
            {
                result = defaultLanguage;
            }
            else
            {
                result = firstAvailable;
            }
        }
        return result;
    }
    
    function tpxGetXMLHTTP()
    {
        var xhttp;
        xhttp = new XMLHttpRequest();
        return xhttp;
    }
    
    function tpxReadCookie(pName)
    {
        var nameEQ = pName + "=";
        var ca = document.cookie.split(";");
        for (var i = 0;i < ca.length; i++)
        {
            var c = ca[i];
            while (c.charAt(0) == ' ')
            {
                c = c.substring(1, c.length);
            }
    
            if (c.indexOf(nameEQ) == 0)
            {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }
    
    function tpxAddGoogleTrackingParameter(pParamArray, pCallback)
    {
        pCallback(pParamArray);
    }
    
    function tpxCreateBasketCountCookie()
    {
        if (gContinueShoppingMessageEnabled)
        {
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + (30 * 24 * 60 * 60 * 1000));
            var basketCountExpires = expireDate.toUTCString();
    
            tpxCreateCookie('mawhlbc', gBasketCount, basketCountExpires);
        }
    }
    
    function tpxCreateCookie(name, value, expires)
    {
        var cookieString = name + "=" + value + "; expires=" + expires + "; path=/";
    
        if (document.location.protocol === 'https:')
        {
            cookieString += "; secure";
        }
    
        document.cookie = cookieString;
    }
    
    function tpxDeleteCookie(name)
    {
        tpxCreateCookie(name, null, "Thu, 01 Jan 1970 00:00:00 UTC");
    }
    
    function tpxGenerateID()
    {
        var result = '';
        var date = new Date();
        var timeStamp = String(date.getTime());
        var len = timeStamp.length;
        var charCode = 0;
    
        for (var i = 0; i < len; i++)
        {
            charCode = timeStamp.charCodeAt(i);
            result += 138 - charCode - i;
        }
    
    return result;
    }
    
    function tpxCreateMAWHLUIDCookie()
    {
        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        var value = tpxGenerateID();
    
        tpxCreateCookie("mawhluid", value, date.toGMTString());
    }
    
    function tpxIsEmpty(obj)
    {
        return (Object.getOwnPropertyNames(obj).length === 0);
    }
    
    function tpxParamString(pSourceString)
    {
        var args = arguments;
    
        for(var i = 0; i < arguments.length; i++)
        {
            pSourceString = pSourceString.replace("^" + String(i-1), args[i]);
        }
    
        return pSourceString;
    }
    
    function tpxCreateRandomString(pLength)
    {
        return Math.round((Math.pow(36, pLength + 1) - Math.random() * Math.pow(36, pLength))).toString(36).slice(1);
    }
    
    function tpxAddGETParam(pURL, pKey, pValue)
    {
        var connector = "?";
    
        if (/[?&]/.test(pURL))
        {
            connector = "&";
        }
    
        return pURL += connector + pKey + "=" + pValue;
    }
     /**
      * Determine whether [clipboardAPI] document.execCommand('copy') is supported
      * Supported Browsers:
      * Firefox 41+, Chrome 42+, Safari 11+, Edge 16+ (76?), IE 10+
      * @param
      */
    function tpxGetSupportsExecCommand()
    {
        // This should be feasible -- but testing on various browsers was inconsistent
        // return document.queryCommandSupported && document.queryCommandSupported("copy");
        var userAgent = navigator.userAgent;
    
        // Version included for Safari
        var versionMatch = userAgent.match(/Version\/\d*/);
    
        var chromeMatch = userAgent.match(/Chrome\/\d*/);
    
        // Chrome on iOS uses CriOS and no Version Number
        var chromeIOSMatch = userAgent.match(/CriOS\/\d*/);
    
        var safariMatch = userAgent.match(/Safari\/\d*/);
    
        var firefoxMatch = userAgent.match(/Firefox\/\d*/);
    
        // Edge browser currently includes 'Chrome'
        var edgeMatch = userAgent.match(/Edge\/\d*/);
    
        // Unclear how stable this check is for IE
        var ieMatch = userAgent.match(/rv:\d*/);
    
        if (chromeMatch && !edgeMatch)
        {
            return parseInt(chromeMatch[0].slice(7)) > 42;
        }
        else if (firefoxMatch)
        {
            return parseInt(firefoxMatch[0].slice(8)) > 41;
        }
        else if (edgeMatch)
        {
            return parseInt(edgeMatch[0].slice(5)) > 17;
        }
        else if (chromeIOSMatch)
        {
            return parseInt(chromeIOSMatch[0].slice(6)) > 42;
        }
        else if (safariMatch && versionMatch && !edgeMatch)
        {
            return parseInt(versionMatch[0].slice(8)) > 11;
        }
        else if (ieMatch)
        {
            return parseInt(ieMatch[0].slice(3)) > 10;
        }
        else
        {
            return false;
        }
    }
    
    function tpxCustomParams()
    {
        var additionalParams = '';
        // Get the query string and strip of the first character (?)
        var query = window.location.search.substr(1);
    
        // Split the remaining string into each param.
        query.split("&").forEach(function(part)
        {
            // If the param starts with cp append this to the additional parameters.
            if (part.substr(0, 2) == 'cp')
            {
                additionalParams += '&' + part;
            }
        });
    
        return additionalParams;
    }

    //Exposed functions
    return {
        init(testMode) {
            if(testMode === true) {
                kServerURL = 'https://taopixstaging.profotonet.nl';
            } else {
                kServerURL = 'https://orders.profotonet.nl';
            }

            tpxStartLoading();
            tpxHighLevelBasketInitialise()
        },
        signIn() {
            tpxStartLoading();
            tpxHighLevelSignInInitControl();
        },
        signOut() {
            tpxHighLevelLogoutControl();
        },
        register() {
            tpxStartLoading();
            tpxHighLevelRegisterInitControl();
        },
        getBasket() {
            tpxStartLoading();
            tpxBasketOnClick();
        },
        emptyBasket() {
            tpxStartLoading();
            tpxHighLevelEmptyBasketControl();
        },
        removeBasketItem(pProjectRef) {
            tpxStartLoading();

            tpxHighLevelRemoveItemFromBasketControl(1, pProjectRef, 1);
        },
        checkout() {
            tpxStartLoading();
            tpxHighLevelCheckoutControl();
        },
        listProjects() {
            tpxStartLoading();
            tpxMyProjectsOnClick();
        },
        duplicateProject(pProjectRef, pCurrentProjectName, pNewProjectName) {
            tpxStartLoading();
            tpxHighLevelDuplicateProjectControl(pProjectRef, pCurrentProjectName, pNewProjectName);
        },
        renameProject(pProjectRef, pNewProjectName) {
            tpxStartLoading();
            tpxHighLevelRenameProjectControl( 1, pProjectRef, true, pNewProjectName);
        },
        editProject(pProjectRef) {
            tpxStartLoading();
            tpxHighLevelEditProjectControl(pProjectRef, 1, 1);
        },
        deleteProject(pProjectRef, pCurrentProjectName) {
            tpxStartLoading();
            tpxHighLevelDeleteProjectControl( 1, pProjectRef, pCurrentProjectName, 1, 0);
        },
        getAccount() {
            tpxStartLoading();
            tpxHighLevelMyAccountInitControl();
        },

    }
}

//exports.TaopixService = TaopixService;
//exports.TaopixObservable = TaopixObservableInstance;

export {TaopixService, TaopixObservableInstance as TaopixObservable}