var language = {
    route: {
        PROJECTS: "projects",
        CART: "cart",
        ACCOUNT: "account",
        MY_PROJECTS: "my-projects",
    },
    configurator: {
        PHOTOBOOKS: "Photobooks",
        CALENDERS: "Calendars",
        PREMIUM: "Premium",
        PREMIUM_PLUS: "Premium Plus",
        PROFESSIONAL: "Professional",
        YEAR_CALENDER: "Annual calendar",
        BIRTHDAY_CALENDER: "Birthday calendar",
        STANDING: "Standing",
        LANDSCAPE: "Landscape",
        SQUARE: "Square",
        LEER_LINNEN_VINYL_COVER: "Standard cover",
        FOTOCOVER_COVER: "Photo cover",
        LASERTEKST_COVER: "Laser engraved cover",
        PLEXIGLAS_COVER: "Plexiglas cover",
        FOTOCOVER_OP_LINNEN_COVER: "Photo cover on linen",
        EMBOSSING_COVER: "Embossing cover",
        DELUXE_COOPER_COVER: "Deluxe Cooper",
        DELUXE_DELON_COVER: "Deluxe Delon",
        DELUXE_NEWMAN_COVER: "Deluxe Newman",
        DELUXE_FOTOCOVER_COVER: "Deluxe Photo cover",
        DELUXE_FRAME_LANDSCAPE_COVER: "Deluxe Frame Landscape",
        DELUXE_FRAME_DETAIL_COVER: "Deluxe Frame Detail",
        DELUXE_FRAME_TRIPLE_COVER: "Deluxe Frame Triple",
        DELUXE_FRAME_DELUXE_COVER: "Deluxe Frame Deluxe",
        BASIS_WIT_KAL: "Basic white",
        BLOK_WIT_KAL: "Block white",
        CIRKEL_WIT_KAL: "Circle white",
        TRANSPARANT_ZWART_KAL: "Transparant black",
        BASIS_ZWART_KAL: "Basic black",
        VIERKANTE_FOTO_WIT_KAL: "Square photo white",
        VIERKANTE_FOTO_ZWART_KAL: "Vierkante photo black",
        TWEE_KOLOMMEN_KAL: "Two columns",
        KOLOM_KAL: "Column",
        BASIS_KAL: "Basic",
        KOLOM_WIT_KAL: "Column white",
        KOLOM_ZWART_KAL: "Column black",
        SOFTCOVER: "Softcover",
        FOTOCOVER: "Photo cover",
        HARDCOVER: "Hardcover"
    },
    PROJECTS: "Projects",
    CART: "Shopping cart",
    MY_PROJECTS: "My projects",
    ACCOUNT: "Account",
    MY_ACCOUNT: "My account",
    MY_CART: "My cart",
    QUESTION: "Questions?",
    PHONENUMBER: "+31 (0) 10-2640410",
    TILL: "until",
    TILLTIME: "17:00",
    AVAILABLE: "reachable.",
    CONFIGURATOR: "Configurator",
    LOGIN: "Login",
    LOGOUT: "Logout",
    TO_SEE_YOUR_PROJECTS: "to see your products",
    TO_SEE_YOUR_ACCOUNT: "to see your account",
    NEW_PROJECT: "+ New project",
    OPEN: "Continue",
    DUPLICATE: "Duplicate",
    CHOOSE_VERSION: "Choose version",
    CHOOSE_SIZE: "Choose format",
    CHOOSE_ORIENTATION: "Choose orientation",
    CHOSEN: "Chosen:",
    CHOOSE_TYPE: "Choose type",
    BACK: "Back",
    CHOOSE_KIND: "Choose kind",
    FROM: "from",
    TYPE: "Type",
    LINE: "Line",
    ORIENTATION: "Orientation",
    SIZE: "Size",
    PRODUCT: "Product",

    CHOOSE_COVER: "Choose cover",

    MAKE_YOUR_PHOTOBOOK: "Or make your photo book with our free offline software",
    DOWNLOAD_FOR_WINDOWS: "Download for Windows",
    DOWNLOAD_FOR_MAC: "Download for Mac",
    DIRECT_BEGINNEN: "Start immediately",
    ADJUST: "Adjust",
    ORDER: "Order",
    DOWNLOAD: "Download",
    MODIFY: "Modify",
    CANCEL: "Cancel",
    YES: "Yes",
    NO: "No",
    DUTCH: "Nederlands",
    BRITISH: "English",
    FRENCH: "Français",
    GERMAN: "Deutsch",
    MADE_ON: "Made on: ",
    REVIEWS_CUSTOMERS: "Reviews 9,8 / 10 - 2907 customers",
    DELIVERY_TIME: "Delivery time photo books max. 7 days",
    HOUSEHOLD_NAME: "A household name among photographers for over 40 years",
    WINNER_QUALITY: "Best quality photo book 2023 winner",
    TO_SHOPPING_CART: "To shopping cart",
    CLOSE: "Close",
    REMOVE_PROJECT_FROM_CART: "Do you want to remove the project from the shopping cart?",
    PROJECT_ADDED_TO_CART: "Project has been added to the cart.",
    RENAME_THE_PROJECT: "Rename the project.",
    NEW_NAME_FOR_THE_PROJECT: "Enter a new name for the project.",
    DELETE_PROJECT: "Do you want to permanently delete the project?",
    COVER: "Cover",
    NOWHITELABEL: "with profotonet logo"

}

export default language;