import React, {Component} from 'react';
import {TaopixObservable, TaopixService} from '../services/taopix-service';

class Container extends Component {
    
    constructor(props, context) {
        super(props, context);

        this.state={
            myAccountURL: undefined
        }

        TaopixObservable.subscribe(this);
        TaopixService().getAccount();

    }

    handleNotification(type, data) {

        if(type == "tpxHighLevelMyAccountInitView") {
            if(data?.myAccountURL && data?.myAccountURL.length > 0) {
                this.setState({
                    myAccountURL: data.myAccountURL
                });
            } else {
                this.setState({
                    myAccountURL: undefined
                });
            }
        }
        //console.log("HANDLE NOTIFICATION", type, data);
    }

    componentWillUnmount() {
        TaopixObservable.unsubscribe(this);
        //console.log("Unmount");
    }

    render() {

        let myAccountURL = this.state.myAccountURL;
        const l = this.props.l;
        let isLoggedIn = this.props.isLoggedIn;

        return(
            <div>

                {/* <h2 style={{paddingLeft: "0px", marginBottom: "0px", borderBottom: "2px solid #617873", paddingBottom: "5px", paddingRight: "0px", width: "auto", cursor: "pointer"}}
                    onClick = {() => {
                        window.location.href = l.route.ACCOUNT
                    }}>
                        {l.MY_ACCOUNT}
                </h2> */}

                <hr />

                    <div className='container'>
                        <div className='row'>
                            {
                                isLoggedIn &&
                                    <div>
                                        <div className='text-center' style={{marginTop: "5%", marginBottom: "5%"}}>
                                            <div className='col-md-12'>
                                                <a style={{textDecoration: "none"}} href= {myAccountURL} >

                                                    <button className='btn btn-dark'>
                                                        {l.LOGIN}
                                                    </button>

                                                    <span className='' style={{marginLeft: "1%", color: "black"}}>
                                                        {l.TO_SEE_YOUR_ACCOUNT}
                                                    </span>
                                                    
                                                </a>
                                            </div>
                                        </div>

                                        <div className='col-md-8' style={{margin: "auto"}}>
                                            <hr />
                                        </div>

                                    </div>
                            }
                        </div>
                    </div>
            </div>
        )
    }
}

export default Container;