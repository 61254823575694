import { tsNeverKeyword } from '@babel/types';
import React, {Component} from 'react';
import {TaopixObservable, TaopixService} from '../services/taopix-service';

import PopupContainer from './popup-container';

class Container extends Component {

    constructor(props, context) {
        super(props, context);

        this.state={
            projectItems: [],
            showPopup: false,
            showPopupDuplicate: false,
            showPopupRename: false,
            showPopupDelete: false,
            pNewProjectName: null,
            pProjectRef: null,
            pProjectName: null,
            projectpreviewthumbnail: null,
            pLayoutname: null,
            newProjectItemsLayoutname: null,
            newProjectItemsProjectref: null,
            newProjectItems: null,
            showPopupLanguage: false
        }

        TaopixObservable.subscribe(this);
        TaopixService().listProjects();
    }

    handleNotification(type, data) {

        if(type == "tpxHighLevelGetProjectListView") {
            if(data?.projectItems && data?.projectItems.length > 0) {
                this.setState({
                    projectItems: data.projectItems
                });
            } else {
                this.setState({
                    projectItems: []
                });
            }
        }
        // console.log("HANDLE NOTIFICATION", type, data);
    }

    componentWillUnmount() {
        TaopixObservable.unsubscribe(this);
        //console.log("Unmount");
    }

    togglePopupDuplicate(pProjectRef, pProjectName) {
        this.setState({
            pProjectRef: pProjectRef,
            pProjectName: pProjectName,
            showPopupDuplicate: !this.state.showPopupDuplicate
        });
    }
    
    togglePopupRename(pProjectRef) {
        this.setState({
            pProjectRef: pProjectRef,
            showPopupRename: !this.state.showPopupRename
        });
    }

    togglePopupDelete(pProjectRef, pProjectName) {
        this.setState({
            pProjectRef: pProjectRef,
            pProjectName: pProjectName,
            showPopupDelete: !this.state.showPopupDelete
        })
    }

    toggleShowPopupLanguage() {
        this.setState({
            showPopupLanguage: !this.state.showPopupLanguage
        })
    }

    getValue(event) {
        this.setState({
            pNewProjectName: event.target.value
        })
    }

    renderLayoutName(projectItem) {
        
        let layoutname = projectItem.layoutname;
        let text;

        if(layoutname && layoutname.indexOf("<p>") > -1) {
            let splitLayoutName = layoutname.split("<p>");
            if(splitLayoutName.length > 0) {
                for(let l = 0; l < splitLayoutName.length; l++) {
                    let splitLayoutNameEntry = splitLayoutName[l];

                    let languageCode = splitLayoutNameEntry.substring(0, 2);
                    let entryName = splitLayoutNameEntry.slice(3);

                    if(languageCode == this.props.languageCode) {
                        text = entryName;
                        break;
                    }
                }
            }
        } else {
            text = layoutname; 
        }

        return(
            <span>
                {text}
            </span>
        )
    }

    render() {
        
        let projectItems = this.state.projectItems;
        let showPopupDuplicate = this.state.showPopupDuplicate;
        let showPopupRename = this.state.showPopupRename;
        let showPopupDelete = this.state.showPopupDelete;
        let showPopupLanguage = this.state.showPopupLanguage;
        let pNewProjectName = this.state.pNewProjectName;
        let pProjectRef = this.state.pProjectRef;
        let pProjectName = this.state.pProjectName;
        const l = this.props.l;
        let isLoggedIn = this.props.isLoggedIn;
        let layoutcode;
        let layoutname;
        let dateandtimecreated;

        return (
            <div className='container'>
                <div className='row'>

                <div className='col' style={{paddingLeft: "0px"}}>
                    <h2 style={{lineHeight: "inherit", borderBottom: "3px solid #617873", width: "fit-content", marginBottom: "0"}} >
                        {l.PROJECTS}
                    </h2>
                </div>

                <div className = 'col align-self-end text-end' style = {{paddingRight: "0px", marginBottom: "1%"}}>
                    {
                        isLoggedIn ?
                            <div>
                                <button className = 'btn btn-dark ' 
                                    style = {{width: "auto", fontSize: "13px", marginRight: "2%"}}
                                    onClick = { () => {
                                        TaopixService().signOut()
                                        location.reload()
                                    }}
                                >
                                    {l.LOGOUT}
                                </button>

                                <img src = "./assets/img/vlaggen/vlag-nl.svg" width={25} 
                                    style = {{cursor: "pointer"}}
                                    onClick={ () => {
                                        this.toggleShowPopupLanguage()
                                    }}
                                />
                            </div>

                        :
                            <div>
                                <button className = 'btn btn-dark ' 
                                    style = {{width: "auto", fontSize: "13px", marginRight: "2%"}}
                                    onClick = { () => {
                                        TaopixService().signIn()
                                    }}
                                >
                                    {l.LOGIN}
                                </button>

                                <img src = "./assets/img/vlaggen/vlag-nl.svg" width={25} 
                                style = {{cursor: "pointer"}}
                                onClick={ () => {
                                    this.toggleShowPopupLanguage()
                                }}
                                />
                            </div>
                    }
                </div> 

                <hr />

                    {
                        showPopupRename ?
                            <PopupContainer
                                showPopupDelete = {false}
                                l = {l}
                                text= {l.RENAME_THE_PROJECT}
                                inputPopup = {this.getValue.bind(this)}
                                sendPopup = { () => {
                                    TaopixService().renameProject(pProjectRef, pNewProjectName)
                                    location.reload()
                                }}
                                closePopup = {this.togglePopupRename.bind(this)}
                                closePopupKruisje = {this.togglePopupRename.bind(this)}
                            />
                                : null
                    }
                    {
                        showPopupDuplicate ?
                            <PopupContainer
                                showPopupDelete = {false}
                                l = {l}
                                text = {l.NEW_NAME_FOR_THE_PROJECT}
                                inputPopup = {this.getValue.bind(this)}
                                sendPopup = { () => {
                                    TaopixService().duplicateProject(pProjectRef, pProjectName, pNewProjectName)
                                    }}
                                closePopup = {this.togglePopupDuplicate.bind(this)}
                                closePopupKruisje = {this.togglePopupDuplicate.bind(this)}
                            />
                                : null
                    }
                    {
                        showPopupDelete ?
                            <PopupContainer
                                showPopupDelete = {true}
                                l = {l}
                                text = {l.DELETE_PROJECT}
                                sendPopup = { () => {
                                    TaopixService().deleteProject(pProjectRef, pProjectName)
                                    location.reload()
                                }}
                                closePopup = {this.togglePopupDelete.bind(this)}
                                closePopupKruisje = {this.togglePopupDelete.bind(this)}
                            />
                                : null
                    }
                    {
                        showPopupLanguage ?
                            <PopupContainer
                                showPopupLanguage = {true}
                                l = {l}
                                sendPopupNederlands = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=nl"
                                }}
                                sendPopupEngels = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=en"
                                }}
                                sendPopupDuits = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=de"
                                }}
                                sendPopupFrans = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=fr"
                                }}
                                closePopupKruisje = {this.toggleShowPopupLanguage.bind(this)}
                        />
                            : null
                    }
                    {
                        isLoggedIn ?
                        <div className=''>

                            <div className='' style={{marginTop: "2%"}}>
                                <a href='/' style={{color: "black"}}>
                                    {l.NEW_PROJECT}
                                </a>
                            </div>

                                {projectItems.map((p, key) => (
                                    <div className='row' key={key} style={{marginTop: "2%"}}>

                                        <div className='col-5 col-sm-5 col-md-4 col-lg-3  row align-items-center' style={{maxWidth: "250px"}}>
                                                <img alt='previewThumbnail' style={{width: "194px", height: "170px", objectFit: "cover"}} src={p.projectpreviewthumbnail} />
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-7 col-lg-6 align-self-center' style={{marginTop: ""}}>
                                            <div className='d-flex' style={{marginTop: ""}}>
                                                <b className='infoTitle' style={{marginRight: "1%", paddingTop: "", fontSize: ""}}>
                                                    {p.projectname}
                                                </b>

                                                <img style={{cursor: "pointer", width: "25px"}} src="./assets/img/icons/edit-pencil.svg" onClick={() => {
                                                    pProjectRef = p.projectref;
                                                    this.togglePopupRename(pProjectRef);
                                                }} >
                                                </img>
                                            </div>

                                            <div className='infoScreen' style={{marginBottom: "3%" }}>
                                                {this.renderLayoutName(p)}
                                                <span> | </span>
                                                {
                                                    layoutcode = p.layoutcode.split("_").splice(1,1)
                                                }
                                            </div>

                                            <div style={{fontSize: "12px", color: "#c8c8c8", marginTop: "0%"}}>
                                                {
                                                    <div>
                                                        {l.MADE_ON} {dateandtimecreated = p.dateandtimecreated.slice(0, -3)}
                                                    </div>
                                                }
                                            </div>

                                            <button className="colorbtn btn btn-dark" style={{marginRight: "2%", marginTop: "3%", hover: "#8dbdb8" }} onClick={() => {
                                                TaopixService().editProject(p.projectref)
                                            }} >
                                                {l.OPEN}
                                            </button>

                                            <button className='colorbtn btn btn-secondary' style={{marginTop: "3%"}} onClick={() => {
                                                pProjectRef = p.projectref;
                                                pProjectName = p.projectname;
                                                this.togglePopupDuplicate(pProjectRef, pProjectName);
                                            }}>
                                                {l.DUPLICATE}
                                            </button>

                                        </div> 

                                        <div className='col-1 col-sm-1 col-md-1 col-lg-2 align-self-center ' style={{marginRight: "0px", padding: "0 0"  }}>
                                            <div className='row '>
                                                <img className='trashbin' style={{marginLeft: "auto", maxWidth: "32px", maxHeight: "32px", cursor: "pointer", paddingBottom: "5px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "5px" }}
                                                    src="./assets/img/icons/trash-bin.svg"
                                                    onMouseOver={e => (e.currentTarget.src = "./assets/img/icons/trash-bin-hover.svg")}
                                                    onMouseOut= {e => (e.currentTarget.src = "./assets/img/icons/trash-bin.svg" )}
                                                    onClick={() => {
                                                        pProjectRef = p.projectref;
                                                        pProjectName = p.projectname;
                                                        this.togglePopupDelete(pProjectRef, pProjectName);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        


                                    </div>
                                ))}

                                <div className='col-md-8 text-center' style={{margin: "auto", marginTop: "5%", visibility: (showPopupRename || showPopupDuplicate || showPopupDelete || showPopupLanguage ? "hidden" : "visible")}}>
                                    <hr />
                                    <a href='/'>
                                        <button className='btn btn-dark' style={{width: "32%", marginTop: "2%", marginBottom: "1%"}}>
                                            {l.NEW_PROJECT}
                                        </button>
                                    </a>
                                </div>

                            </div>
                        :
                            <div>
                                <div className='text-center' style={{marginTop: "5%", marginBottom: "5%"}}>
                                    <div className='col-md-12'>
                                        <button className='btn btn-dark' onClick={() => {
                                            TaopixService().signIn();
                                        }}>
                                            {l.LOGIN}
                                        </button>
                                        <span className='' style={{marginLeft: "1%"}}>
                                            {l.TO_SEE_YOUR_PROJECTS}
                                        </span>
                                    </div>
                                </div>

                                <div className='col-md-8' style={{margin: "auto", visibility: (showPopupRename || showPopupDuplicate || showPopupDelete || showPopupLanguage ? "hidden" : "visible")}}>
                                    <hr />
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }

}
export default Container;