import React, {Component} from 'react';

import {parseQueryString} from '../utils/Url';
import ReactTagManager from '../utils/gtm';

import data from '../settings/configurator.json';
import {TaopixObservable, TaopixService} from '../services/taopix-service';
import PopupContainer from './popup-container';


class Container extends Component {

    constructor(props, context) {
        super(props, context);

        //Create configurator key-value mapping
        let configuratorKeyValue = {};
        for(let t = 0; t < data.length; t++) {
            let type = data[t];
            configuratorKeyValue[type.name] = {
                index: t,
                lines: {}
            };
            let lines = type.lines;
            for(let l = 0; l < lines.length; l++) {
                let line = lines[l];
                configuratorKeyValue[type.name].lines[line.name] = {
                    index: l,
                    covers: {},
                    orientations: {}
                };

                let orientations = line.orientations;

                if (orientations === undefined) {
                    let covers = line.covers;
                    for(let c = 0; c < covers.length; c++) {
                        let cover = covers[c];
                        configuratorKeyValue[type.name].lines[line.name].covers[cover.name] = {
                            index: c,
                            orientations: {}
                        }

                        let orientations = cover.orientations;
                        for(let o = 0; o < orientations.length; o++) {
                            let orientation = orientations[o];
                            configuratorKeyValue[type.name].lines[line.name].covers[cover.name].orientations[orientation.name] = {
                                index: o,
                                sizes: {}
                            };
                            let sizes = orientation.sizes;
                            for(let s = 0; s < sizes.length; s++) {
                                let size = sizes[s];
                                configuratorKeyValue[type.name].lines[line.name].covers[cover.name].orientations[orientation.name].sizes[size.name] = {
                                    index: s
                                };

                            }
                        }
                    } 
                } else {
                    for (let o = 0; o < orientations.length; o++) {
                        let orientation = orientations[o];
                        configuratorKeyValue[type.name].lines[line.name].orientations[orientation.name] = {
                            index: o,
                            sizes: {}
                        };

                        let sizes = orientation.sizes;
                        for(let s = 0; s < sizes.length; s++) {
                            let size = sizes[s];
                            configuratorKeyValue[type.name].lines[line.name].orientations[orientation.name].sizes[size.name] = {
                                index: s
                            };
                        }
                    }
                }
            }
        }

        //platform
        let platformName = navigator.userAgent;
        let isMac = false;

        isMac = platformName.indexOf("Macintosh")
        if (isMac > -1) {
                isMac = true
        } else {
                isMac = false
        }

        this.state = {
            type: undefined,
            line: undefined,
            orientation: undefined,
            size: undefined,
            F: undefined,
            clickedLineName: undefined,
            clickedOrientationName: undefined,
            clickedSizeName: undefined,
            clickedProductName: undefined,
            isMac: isMac,
            loading: false,
            configuratorKeyValue: configuratorKeyValue,
            showPopupLanguage: false,
            cover: undefined,
            clickedCoverName: undefined,
            l: this.props.l,
            bar: undefined
        }

        //Load URL params after setting the state
        this.loadUrlParams();
    }

    //Load the URL params to preset the configurator values
    loadUrlParams() {

        let queryParams = new URLSearchParams(window.location.search);
        let configuratorKeyValue = this.state.configuratorKeyValue;

        let type;
        let typeName;
        let line;
        let lineName;
        let orientation;
        let orientationName;
        let size;
        let sizeName;

        let coverName;
        let cover;

        //Handle the queryParams
        if(queryParams) {

            //Type
            typeName = queryParams.get("type");
            if(configuratorKeyValue[typeName]) {
                type = configuratorKeyValue[typeName].index;

                //Line
                lineName = queryParams.get("line");
                if(configuratorKeyValue[typeName].lines[lineName]) {
                    line = configuratorKeyValue[typeName].lines[lineName].index;
                    coverName = queryParams.get("cover");
                    if(configuratorKeyValue[typeName].lines[lineName].covers[coverName]) {
                        cover = configuratorKeyValue[typeName].lines[lineName].covers[coverName].index;

                        //Orientation
                        orientationName = queryParams.get("orientation");
                        if(configuratorKeyValue[typeName].lines[lineName].covers[coverName].orientations[orientationName]) {
                            orientation = configuratorKeyValue[typeName].lines[lineName].covers[coverName].orientations[orientationName].index;
        
                            //Size
                            sizeName = queryParams.get("size");
                            if(configuratorKeyValue[typeName].lines[lineName].covers[coverName].orientations[orientationName].sizes[sizeName]) {
                                size = configuratorKeyValue[typeName].lines[lineName].covers[coverName].orientations[orientationName].sizes[sizeName].index;
                            }
                        }
                    } else {
                        orientationName = queryParams.get("orientation");
                        if(configuratorKeyValue[typeName].lines[lineName].orientations[orientationName]) {
                            orientation = configuratorKeyValue[typeName].lines[lineName].orientations[orientationName].index;
            
                            //Size
                            sizeName = queryParams.get("size");
                            if(configuratorKeyValue[typeName].lines[lineName].orientations[orientationName].sizes[sizeName]) {
                                size = configuratorKeyValue[typeName].lines[lineName].orientations[orientationName].sizes[sizeName].index;
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            type: (type || type === 0 ? type : undefined),
            line: (line || line === 0 ? line : undefined),
            orientation: (orientation || orientation === 0 ? orientation : undefined),
            size: (size || size === 0 ? size : undefined),
            clickedTypeName: typeName || undefined,
            clickedLineName: lineName || undefined,
            clickedOrientationName: orientationName || undefined,
            clickedSizeName: sizeName || undefined,
            clickedProductName: undefined,

            cover: (cover || cover === 0 ? cover : undefined),
            clickedCoverName: coverName || undefined
        })
    }

    componentWillReceiveProps() {
        
        //Always reload the URL params when a new props are set to handle navigation forward and backward
        this.loadUrlParams();
    }

    toggleShowPopupLanguage() {
        this.setState({
            showPopupLanguage: !this.state.showPopupLanguage
        })
    }

    //addToCartEvent
    addToCartEvent(url) {

        let l = this.state.l;

        //Set testMode based on the hostname
        let testMode = false;
        if(window?.location?.hostname?.includes("mlbasket.profotonet.nl")) {
            testMode = true;
        }

        let line = (this.state.clickedLineName ? l.configurator[this.state.clickedLineName] : this.state.clickedLineName);
        let size = (this.state.clickedSizeName ? this.state.clickedSizeName.toLowerCase() : this.state.clickedSizeName);
        let cover = (this.state.clickedCoverName ? l.configurator[this.state.clickedCoverName] : this.state.clickedCoverName);
        
        let productName = line + " " + size + " " + cover;

        //Add to cart event
        if(!testMode) {

            //Trigger add to cart event
            let addToCartEventData = {
                dataLayer: {
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "EUR",
                        items: [
                            {
                                item_name: productName,
                            }
                        ]
                    }
                }
            };

            ReactTagManager.dataLayer(addToCartEventData);

        }

        //Redirect
        setTimeout(() => {
            window.location.href = url;
        }, 500);

    }

    renderProducts(l, testMode, nameFPLC, nameGA, namePFN_CID) {
                        
        let _this = this;

        return (
            <div className="row text-center" style = {{marginTop: "1%"}}>

                {
                    this.state.clickedSizeName !== undefined && this.state.clickedSizeName &&
                        <div className='breadcrumbsText text-center' style={{paddingLeft: "0px", paddingRight: "0px"}}>
                            <span>
                                {l.CHOSEN}   
                            </span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        type: undefined,
                                        line: undefined,
                                        cover: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        clickedTypeName: undefined,
                                        clickedLineName : undefined,
                                        clickedCoverName: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined,
                                    })
                                }}
                            >
                                {(this.state.clickedTypeName && l.configurator[this.state.clickedTypeName] ? l.configurator[this.state.clickedTypeName] : this.state.clickedTypeName)}
                            </a>

                            <span> -</span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        line: undefined,
                                        orientation: undefined,
                                        cover: undefined,
                                        size: undefined,
                                        clickedLineName : undefined,
                                        clickedCoverName: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedLineName && l.configurator[this.state.clickedLineName] ? l.configurator[this.state.clickedLineName] : this.state.clickedLineName)}
                            </a>

                            {
                                this.state.clickedLineName === "PREMIUM" &&
                                    <span> -</span>
                            }
                            {
                                this.state.clickedLineName === "PREMIUM" &&
                                    <a 
                                        style = {{
                                            color: "#617873", 
                                            cursor: "pointer", 
                                            marginLeft: "5px", 
                                            textDecoration: "underline", 
                                            textDecorationColor: "#617873"
                                        }}
                                        onClick = { () => {
                                            this.setState({
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedCoverName : undefined,
                                                clickedOrientationName  : undefined,
                                                clickedSizeName : undefined
                                            })
                                        }}
                                >
                                    {(this.state.clickedCoverName && l.configurator[this.state.clickedCoverName] ? l.configurator[this.state.clickedCoverName] : this.state.clickedCoverName)}
                                </a>
                            }

                            <span> -</span>

                            <a 
                                style = {{ 
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        orientation: undefined,
                                        size: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedOrientationName && l.configurator[this.state.clickedOrientationName] ? l.configurator[this.state.clickedOrientationName] : this.state.clickedOrientationName)}
                            </a>

                            <span> -</span>

                            <a 
                                style = {{ 
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick={() => {
                                    this.setState({
                                        size: undefined,
                                        clickedSizeName : undefined,

                                    })
                                }}
                            >
                                {(this.state.clickedSizeName && l.configurator[this.state.clickedSizeName] ? l.configurator[this.state.clickedSizeName].toLowerCase() + " cm" : this.state.clickedSizeName.toLowerCase() + " cm" )}
                            </a>
                        </div>
                }
                <h3 style={{marginTop: "4%", marginBottom: "5%"}}>{l.CHOOSE_VERSION}</h3>

                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8' style={{margin: "auto"}}>
                    <div className='row'>
                        {
                            testMode === true && 
                                <div className='product col-sm-12 col-md-6 col-lg-4'>
                                    {/* <a href= {"https://taopixstaging.profotonet.nl/?fsaction=OnlineAPI.create&id=69.MGIyY2RiNDk.jhRZtCmiUxCKkDZgC5AlTpW879quCaYm93TJwIY1f1sbKtTS6mnhJloNmJjPBHdcNF4853Q6tlXZzmLxFsun57ZzgWEsmT7p&" + nameGA + "&" + nameFPLC}  style={{color: "black", textDecoration: "none"}}> */}
                                    <a onClick={(e) => {
                                        _this.addToCartEvent("https://taopixstaging.profotonet.nl/?fsaction=OnlineAPI.create&id=69.MGIyY2RiNDk.jhRZtCmiUxCKkDZgC5AlTpW879quCaYm93TJwIY1f1sbKtTS6mnhJloNmJjPBHdcNF4853Q6tlXZzmLxFsun57ZzgWEsmT7p&" + nameGA + "&" + namePFN_CID + "&" + nameFPLC);
                                    }} href="#" style={{color: "black", textDecoration: "none"}}>
                                        
                                        <div>
                                            <img src="/assets/img/configurator/fotoboeken/orientatie-fotoboek-algemeen-staand.svg" width="200" />
                                        </div>


                                        <div style={{marginTop: "3px"}}>

                                            <div>
                                                TEST PRODUCT
                                            </div>
                                            
                                            <div style={{marginLeft: "5px", fontSize: "13px"}}>
                                                {l.FROM} € 1999,00
                                            </div>

                                            <div style={{marginTop: "10px"}}>
                                                <button className='btn' style={{color: "white", width: "200px", maxWidth: "80%"}}>
                                                    {l.DIRECT_BEGINNEN}
                                                </button>
                                            </div>
                                        
                                        </div>

                                    </a>
                                </div>
                        }
                        {
                            this.state.clickedLineName && this.state.clickedLineName === "PREMIUM" &&
                                data[this.state.type].lines[this.state.line].covers[this.state.cover].orientations[this.state.orientation].sizes[this.state.size].products.map(function(product, p) {
                                    return (
                                        <div className= {_this.state.clickedCoverName === "SOFTCOVER" ? 'product col-sm-12 col-md-12 col-lg-12' : 'product col-sm-12 col-md-6 col-lg-4' } key={p} style={{padding : "15px"}} >
                                            <a onClick={(e) => {
                                                _this.addToCartEvent(product.url + "&" + nameGA + "&" + namePFN_CID + "&" + nameFPLC);
                                            }} href="#" style={{color: "black", textDecoration: "none"}}>
                                                <div >
                                                    {
                                                        product.imageOnhover ?
                                                            <img 
                                                                width="250" 
                                                                src={product.image} 
                                                                onMouseOver = {e => (e.currentTarget.src = product.imageOnhover )}
                                                                onMouseOut = {e => (e.currentTarget.src = product.image )}
                                                            />
                                                            :
                                                            <img 
                                                                width="250" 
                                                                src={product.image} 
                                                            />
                                                    }
                                                </div>

                                                <div style={{marginTop: "3px"}}>

                                                    <div>
                                                        {(product.name && l.configurator[product.name] ? l.configurator[product.name] : product.name)}
                                                    </div>

                                                    <div style={{fontSize: "12px", fontStyle: "italic" }}>
                                                        {(product.noWhiteLabel && l[product.noWhiteLabel] ? l[product.noWhiteLabel] : product.noWhiteLabel )}
                                                    </div>

                                                    <div className='' style={{fontSize: "13px", marginTop: "5px"}}>
                                                        {l.FROM} € {product.from}
                                                    </div>

                                                    <div style={{marginTop: "10px"}}>
                                                        <button className='btn' style={{color: "white", width: "200px", maxWidth: "80%"}}>
                                                            {l.DIRECT_BEGINNEN}
                                                        </button>
                                                    </div>

                                                </div>
                                            </a>
                                        </div>
                                    )
                                })                                 
                        }
                        {
                            this.state.clickedLineName && this.state.clickedLineName !== "PREMIUM" &&
                                data[this.state.type].lines[this.state.line].orientations[this.state.orientation].sizes[this.state.size].products.map(function(product, p) {
                                    return (
                                        <div className='product col-sm-12 col-md-6 col-lg-4' key={p} style={{padding : "15px"}} >
                                            <a onClick={(e) => {
                                                _this.addToCartEvent(product.url + "&" + nameGA + "&" + namePFN_CID + "&" + nameFPLC);
                                            }} href="#" style={{color: "black", textDecoration: "none"}}>
                                                <div >
                                                    {
                                                        product.imageOnhover ?
                                                            <img 
                                                                width="250" 
                                                                src={product.image} 
                                                                onMouseOver = {e => (e.currentTarget.src = product.imageOnhover )}
                                                                onMouseOut = {e => (e.currentTarget.src = product.image )}
                                                            />
                                                            :
                                                            <img 
                                                                width="250" 
                                                                src={product.image} 
                                                            />
                                                    }
                                                </div>
                                                <div style={{marginTop: "3px"}}>
                                                    <div>
                                                        {(product.name && l.configurator[product.name] ? l.configurator[product.name] : product.name)}
                                                    </div>
                                                    <div className='' style={{fontSize: "13px", marginTop: "5px"}}>
                                                        {l.FROM} € {product.from}
                                                    </div>
                                                    <div style={{marginTop: "10px"}}>
                                                        <button className='btn' style={{color: "white", width: "200px", maxWidth: "80%"}}>
                                                            {l.DIRECT_BEGINNEN}
                                                        </button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div>
                {
                    this.state.clickedSizeName !== undefined && this.state.clickedSizeName &&
                        <div className='text-center' style={{marginTop: "5%"}}>
                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873", 
                                    padding: "10px 10px 10px 10px"}}
                                onClick = { () => {
                                    this.setState({
                                        size: undefined,
                                        clickedSizeName: this.state.clickedSizeName
                                    })
                                }}
                            >
                                {l.BACK}
                            </a>
                        </div>
                }
            </div>
        )
    }

    renderSizes(l) {
        let _this = this;
        return (
            <div className="row text-center" style={{marginTop: "1%"}}>
                {
                    this.state.clickedOrientationName !== undefined && this.state.clickedOrientationName &&
                        <div className='breadcrumbsText text-center'>
                            <span>
                                {l.CHOSEN}   
                            </span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        type: undefined,
                                        line: undefined,
                                        cover: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        clickedTypeName: undefined,
                                        clickedLineName : undefined,
                                        clickedCoverName: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedTypeName && l.configurator[this.state.clickedTypeName] ? l.configurator[this.state.clickedTypeName] : this.state.clickedTypeName)}
                            </a>

                            <span> -</span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        line: undefined,
                                        orientation: undefined,
                                        cover: undefined,
                                        size: undefined,
                                        clickedLineName : undefined,
                                        clickedCoverName: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedLineName && l.configurator[this.state.clickedLineName] ? l.configurator[this.state.clickedLineName] : this.state.clickedLineName)}
                            </a>
                            {
                                this.state.clickedLineName === "PREMIUM" &&
                                    <span> -</span>
                            }
                            {
                                this.state.clickedLineName === "PREMIUM" &&
                                    <a 
                                        style = {{
                                            color: "#617873", 
                                            cursor: "pointer", 
                                            marginLeft: "5px", 
                                            textDecoration: "underline", 
                                            textDecorationColor: "#617873"
                                        }}
                                        onClick = { () => {
                                            this.setState({
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedCoverName : undefined,
                                                clickedOrientationName  : undefined,
                                                clickedSizeName : undefined
                                            })
                                        }}
                                >
                                    {(this.state.clickedCoverName && l.configurator[this.state.clickedCoverName] ? l.configurator[this.state.clickedCoverName] : this.state.clickedCoverName)}
                                </a>
                            }
                            <span> -</span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        orientation: undefined,
                                        size: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedOrientationName && l.configurator[this.state.clickedOrientationName] ? l.configurator[this.state.clickedOrientationName] : this.state.clickedOrientationName)}
                            </a>
                        </div>
                }
                    <h3 style={{marginTop: "4%", marginBottom: "5%"}}>{l.CHOOSE_SIZE}</h3>
                {
                    this.state.clickedOrientationName !== undefined && (this.state.clickedOrientationName === "STANDING" || this.state.clickedOrientationName === "LANDSCAPE" || this.state.clickedOrientationName === "SQUARE") && this.state.clickedLineName !== "PREMIUM" &&
                        <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6' style={{margin: "auto"}}>
                            <div className='row'>
                                {
                                    data[this.state.type].lines[this.state.line].orientations[this.state.orientation].sizes.map(function(size, s) {
                                        return (
                                            <div className='configurHover col' key={s}
                                                onClick = { () => {
                                                    
                                                    const url = new URL(window.location);
                                                    if(url && url.searchParams) {
                                                        url.searchParams.set('size', size.name);
                                                        window.history.pushState(null, '', url.toString());
                                                    }

                                                    _this.setState({
                                                        size: s,
                                                        clickedSizeName: size.name
                                                    })
                                                }}
                                            >
                                                <div>
                                                    <img src={size.configurImage} width="150" />
                                                </div>

                                                <div className='titleName'>
                                                    {(size.name && l.configurator[size.name] ? l.configurator[size.name].toLowerCase() + " cm" : size.name.toLowerCase() + " cm" )}
                                                </div>

                                                <div>
                                                    <span style={{marginLeft: "", fontSize: "13px"}}>
                                                        {l.FROM} € {size.from}
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    this.state.clickedOrientationName !== undefined && (this.state.clickedOrientationName === "LANDSCAPE" || this.state.clickedOrientationName === "STANDING" || this.state.clickedOrientationName === "SQUARE") && this.state.clickedLineName === "PREMIUM" &&
                        <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6' style={{margin: "auto"}}>
                            <div className='row'>
                                {
                                    data[this.state.type].lines[this.state.line].covers[this.state.cover].orientations[this.state.orientation].sizes.map(function(size, s) {
                                        return (
                                            <div className='configurHover col' key={s}
                                                onClick = { () => {
                                                    const url = new URL(window.location);
                                                    if(url && url.searchParams) {
                                                        url.searchParams.set('size', size.name);
                                                        window.history.pushState(null, '', url.toString());
                                                    }

                                                    _this.setState({
                                                        size: s,
                                                        clickedSizeName: size.name
                                                    })
                                                }}
                                            >
                                                <div>
                                                    <img src={size.configurImage} width="150" />
                                                </div>

                                                <div className='titleName'>
                                                    {(size.name && l.configurator[size.name] ? l.configurator[size.name].toLowerCase() + " cm" : size.name.toLowerCase() + " cm" )}
                                                </div>

                                                <div>
                                                    <span style={{marginLeft: "", fontSize: "13px"}}>
                                                        {l.FROM} € {size.from}
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    this.state.clickedOrientationName !== undefined && this.state.clickedOrientationName &&
                        <div className='text-center' style={{marginTop: "5%"}}>
                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873", 
                                    padding: "10px 10px 10px 10px"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        orientation: undefined,
                                        clickedOrientationName: this.state.clickedOrientationName,
                                    })
                                }}
                            >
                                {l.BACK}
                            </a>
                        </div>
                }
            </div>
        )
    }

    renderOrientations(l) {
        let _this = this;
        return (
            <div className="row text-center" style={{marginTop: "1%"}}>
                {
                    this.state.clickedLineName !== undefined && this.state.clickedLineName &&
                        <div className='breadcrumbsText text-center'>
                            <span>
                                {l.CHOSEN}   
                            </span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        type: undefined,
                                        line: undefined,
                                        cover: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        clickedTypeName: undefined,
                                        clickedLineName : undefined,
                                        clickedCoverName: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedTypeName && l.configurator[this.state.clickedTypeName] ? l.configurator[this.state.clickedTypeName] : this.state.clickedTypeName)}
                            </a>

                            <span> -</span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"}}
                                onClick = { () => {
                                    this.setState({
                                        line: undefined,
                                        cover: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        clickedLineName : undefined,
                                        clickedCoverName: undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedLineName && l.configurator[this.state.clickedLineName] ? l.configurator[this.state.clickedLineName] : this.state.clickedLineName)}
                            </a>

                            {
                                this.state.clickedLineName === "PREMIUM" &&
                                    <span> -</span>
                            }
                            {
                                this.state.clickedLineName === "PREMIUM" &&
                                    <a 
                                        style = {{
                                            color: "#617873", 
                                            cursor: "pointer", 
                                            marginLeft: "5px", 
                                            textDecoration: "underline", 
                                            textDecorationColor: "#617873"
                                        }}
                                        onClick = { () => {
                                            this.setState({
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedCoverName : undefined,
                                                clickedOrientationName  : undefined,
                                                clickedSizeName : undefined
                                            })
                                        }}
                                >
                                    {(this.state.clickedCoverName && l.configurator[this.state.clickedCoverName] ? l.configurator[this.state.clickedCoverName] : this.state.clickedCoverName)}
                                </a>
                            }

                        </div>
                }
                    <h3 style={{marginTop: "4%", marginBottom: "5%"}}>{l.CHOOSE_ORIENTATION}</h3>
                {
                    this.state.clickedLineName !== undefined && ( this.state.clickedLineName === "PREMIUM_PLUS" || this.state.clickedLineName === "PROFESSIONAL") &&
                        <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6' style={{margin: "auto"}}>
                            <div className='row '>
                                {
                                    data[this.state.type].lines[this.state.line].orientations.map(function(orientation, o) {
                                        return (
                                            <div className='configurHover col' key={o}
                                                onClick={() => {
                                                    
                                                    const url = new URL(window.location);
                                                    if(url && url.searchParams) {
                                                        url.searchParams.set('orientation', orientation.name);
                                                        url.searchParams.delete('size');
                                                        window.history.pushState(null, '', url.toString());
                                                    }

                                                    _this.setState({
                                                        orientation: o,
                                                        clickedOrientationName: orientation.name
                                                    })
                                                }}
                                            >
                                                <div className=''>
                                                    <img src={orientation.configurImage} width="150" />
                                                </div>

                                                <div className='titleName'>
                                                    {(orientation.name && l.configurator[orientation.name] ? l.configurator[orientation.name] : orientation.name)}
                                                </div>

                                                <div>
                                                    <span style={{marginLeft: "", fontSize: "13px"}}>
                                                        {l.FROM} € {orientation.from}
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    this.state.clickedLineName !== undefined && (this.state.clickedCoverName === "SOFTCOVER" || this.state.clickedCoverName === "HARDCOVER") &&
                    <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6' style={{margin: "auto"}}>
                        <div className='row '>
                            {
                                data[this.state.type].lines[this.state.line].covers[this.state.cover].orientations.map(function(orientation, o) {
                                    return (
                                        <div className='configurHover col' key={o}
                                            onClick={() => {

                                                const url = new URL(window.location);
                                                if(url && url.searchParams) {
                                                    url.searchParams.set('orientation', orientation.name);
                                                    url.searchParams.delete('size');
                                                    window.history.pushState(null, '', url.toString());
                                                }

                                                _this.setState({
                                                    orientation: o,
                                                    clickedOrientationName: orientation.name
                                                })
                                            }}
                                        >
                                            <div className=''>
                                                <img src={orientation.configurImage} width="150" />
                                            </div>

                                            <div className='titleName'>
                                                {(orientation.name && l.configurator[orientation.name] ? l.configurator[orientation.name] : orientation.name)}
                                            </div>

                                            <div>
                                                <span style={{marginLeft: "", fontSize: "13px"}}>
                                                    {l.FROM} € {orientation.from}
                                                </span>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    this.state.clickedLineName !== undefined && (this.state.clickedLineName === "YEAR_CALENDER" || this.state.clickedLineName === "BIRTHDAY_CALENDER") &&
                        <div className='col-sm-12 col-md-8 col-lg-6' style={{margin: "auto"}}>
                            <div className='row '>
                                {
                                    data[this.state.type].lines[this.state.line].orientations.map(function(orientation, o) {
                                        return (
                                            <div className='configurHover col' key={o}
                                                onClick = { () => {

                                                    const url = new URL(window.location);
                                                    if(url && url.searchParams) {
                                                        url.searchParams.set('orientation', orientation.name);
                                                        url.searchParams.delete('size');
                                                        window.history.pushState(null, '', url.toString());
                                                    }

                                                    _this.setState({
                                                        orientation: o,
                                                        clickedOrientationName: orientation.name
                                                    })
                                                }} 
                                            >
                                                <div>
                                                    <img src={orientation.configurImage} width="150" />
                                                </div>

                                                <div className='titleName'>
                                                    {(orientation.name && l.configurator[orientation.name] ? l.configurator[orientation.name] : orientation.name)}
                                                </div>

                                                <div>
                                                    <span style={{marginLeft: "", fontSize: "13px"}}>
                                                        {l.FROM} € {orientation.from}
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    this.state.clickedLineName !== undefined && this.state.clickedLineName && this.state.clickedLineName !== "PREMIUM" &&
                        <div className='text-center' style={{marginTop: "5%"}}>
                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873", 
                                    padding: "10px 10px 10px 10px"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        line: undefined,
                                        clickedLineName: this.state.clickedLineName,
                                    })
                                }}
                            >
                                {l.BACK}
                            </a>
                        </div>
                }
                {
                    this.state.clickedLineName !== undefined && this.state.clickedLineName && this.state.clickedLineName === "PREMIUM" &&
                        <div className='text-center' style={{marginTop: "5%"}}>
                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873", 
                                    padding: "10px 10px 10px 10px"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        cover: undefined,
                                        clickedCoverName: undefined
                                    })
                                }}
                            >
                                {l.BACK}
                            </a>
                        </div>
                }
            </div>
        )
    }

    renderCovers(l) {
        let _this = this;

        return (
            <div className='row text-center' style={{marginTop: "1%"}}>
                {
                    this.state.clickedLineName !== undefined && this.state.clickedLineName &&
                        <div className='breadcrumbsText text-center'>
                            <span>
                                {l.CHOSEN}
                            </span>

                            <a
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = {() => {
                                    this.setState({
                                        type: undefined,
                                        line: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        cover: undefined,
                                        clickedTypeName: undefined,
                                        clickedLineName : undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined,
                                        clickedCoverName: undefined
                                    })
                                }}
                            >
                                {(this.state.clickedTypeName && l.configurator[this.state.clickedTypeName] ? l.configurator[this.state.clickedTypeName] : this.state.clickedTypeName )}
                            </a>

                            <span> -</span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"}}
                                onClick = { () => {
                                    this.setState({
                                        line: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        clickedLineName : undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined,
                                        cover: undefined,
                                        clickedCoverName: undefined
                                    })
                                }}
                            >
                                {(this.state.clickedLineName && l.configurator[this.state.clickedLineName] ? l.configurator[this.state.clickedLineName] : this.state.clickedLineName)}
                            </a>

                        </div>
                }
                    <h3 style={{marginTop: "4%", marginBottom: "5%"}}>{l.CHOOSE_COVER}</h3>
                {
                    this.state.clickedLineName !== undefined && (this.state.clickedLineName === "PREMIUM") &&
                        <div className='col-sm-12 col-md-8 col-lg-6' style={{margin: "auto"}}>
                            <div className='row'>
                                {
                                    data[this.state.type].lines[this.state.line].covers.map(function(cover, c) {
                                        return (
                                                <div className='configurHover col' key={c}
                                                    onClick={() => {
                                                        
                                                        const url = new URL(window.location);
                                                        if(url && url.searchParams) {
                                                            url.searchParams.set('cover', cover.name);
                                                            url.searchParams.delete('orientation');
                                                            url.searchParams.delete("size");
                                                            window.history.pushState(null, '', url.toString());
                                                        }

                                                        _this.setState({
                                                            cover: c,
                                                            clickedCoverName: cover.name
                                                        })
                                                    }}
                                                >
                                                    <div>
                                                        <img src={cover.configurImage} width="150" />
                                                    </div>

                                                    <div className='titleName'>
                                                        {(cover.name && l.configurator[cover.name] ? l.configurator[cover.name] : cover.name)}
                                                    </div>

                                                    <div style={{fontSize: "12px", fontStyle: "italic" }}>
                                                        {(cover.noWhiteLabel && l[cover.noWhiteLabel] ? l[cover.noWhiteLabel] : cover.noWhiteLabel )}
                                                    </div>

                                                    <div>
                                                        <span style={{marginLeft: "", fontSize: "13px"}}>
                                                            {l.FROM} € {cover.from}
                                                        </span>
                                                    </div>
                                                </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    this.state.clickedLineName !== undefined && this.state.clickedLineName === "PREMIUM" &&
                        <div className='text-center' style={{marginTop: "5%"}}>
                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873", 
                                    padding: "10px 10px 10px 10px"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        line: undefined,
                                        clickedLineName: undefined
                                    })
                                }}
                            >
                                {l.BACK}
                            </a>
                        </div>
                }
            </div>
        )
    }

    renderLines(l) {

        let _this = this;

        return (
            <div className="row text-center" style={{marginTop: "1%"}}>
                {
                    this.state.clickedTypeName !== undefined && this.state.clickedTypeName &&
                        <div className='breadcrumbsText text-center'>
                            <span>
                                {l.CHOSEN}   
                            </span>

                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    marginLeft: "5px", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        type: undefined,
                                        line: undefined,
                                        orientation: undefined,
                                        size: undefined,
                                        clickedTypeName: undefined,
                                        clickedLineName : undefined,
                                        clickedOrientationName  : undefined,
                                        clickedSizeName : undefined
                                    })
                                }}
                            >
                                {(this.state.clickedTypeName && l.configurator[this.state.clickedTypeName] ? l.configurator[this.state.clickedTypeName] : this.state.clickedTypeName)}
                            </a>
                        </div>
                }
                    <h3 style={{marginTop: "4%", marginBottom: "5%"}}>{l.CHOOSE_TYPE}</h3>
                {
                    this.state.clickedTypeName !== undefined && this.state.clickedTypeName === "PHOTOBOOKS" &&
                        <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6' style={{margin: "auto"}}>
                            <div className='row'>
                                {
                                    data[this.state.type].lines.map(function(line, z) {
                                        return (
                                            <div className='configurHover col' key={z} 
                                                onClick = { () => {
                                                    
                                                    const url = new URL(window.location);
                                                    if(url && url.searchParams) {
                                                        url.searchParams.set('line', line.name);
                                                        url.searchParams.delete('orientation');
                                                        url.searchParams.delete('size');
                                                        window.history.pushState(null, '', url.toString());
                                                    }

                                                    _this.setState({
                                                        line: z,
                                                        clickedLineName: line.name
                                                    })
                                                }}>

                                                <div className=''>
                                                    <img src={line.configurImage} width="150" />
                                                </div>

                                                <div className='titleName'>
                                                    {(line.name && l.configurator[line.name] ? l.configurator[line.name] : line.name)}
                                                </div>

                                                <div className=''>
                                                    <span style={{marginLeft: "", fontSize: "13px"}}>
                                                        {l.FROM} € {line.from}
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    this.state.clickedTypeName !== undefined && this.state.clickedTypeName === "CALENDERS" &&
                        <div className='col-sm-12 col-md-8 col-lg-6' style={{margin: "auto"}}>
                            <div className='row'>
                                {
                                    data[this.state.type].lines.map(function(line, z) {
                                        return (
                                            <div className='configurHover col-6 col' key={z}
                                                onClick = { () => {

                                                    const url = new URL(window.location);
                                                    if(url && url.searchParams) {
                                                        url.searchParams.set('line', line.name);
                                                        url.searchParams.delete('orientation');
                                                        url.searchParams.delete('size');
                                                        window.history.pushState(null, '', url.toString());
                                                    }

                                                    _this.setState({
                                                        line: z,
                                                        clickedLineName: line.name
                                                    })
                                                }}
                                            >
                                                <div>
                                                    <img src={line.configurImage} width="150" />
                                                </div>

                                                <div className='titleName'>
                                                    {(line.name && l.configurator[line.name] ? l.configurator[line.name] : line.name)}
                                                </div>

                                                <div>
                                                    <span style={{marginLeft: "", fontSize: "13px"}}>
                                                        {l.FROM} € {line.from}
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }   
                            </div>
                        </div>
                }
                {
                    this.state.clickedTypeName !== undefined && this.state.clickedTypeName &&
                        <div className='text-center' style={{marginTop: "5%"}}>
                            <a 
                                style = {{
                                    color: "#617873", 
                                    cursor: "pointer", 
                                    textDecoration: "underline", 
                                    textDecorationColor: "#617873", 
                                    padding: "10px 10px 10px 10px"
                                }}
                                onClick = { () => {
                                    this.setState({
                                        type: undefined,
                                        clickedTypeName: undefined
                                    })
                                }}
                            >
                                {l.BACK}
                            </a>
                        </div>
                }
            </div>
        )
    }

    renderTypes(l) {

        let _this = this;

        return (
            <div className="row text-center" style={{marginTop: "1%"}}>
                <h3 style={{marginTop: "4%", marginBottom: "5%"}}>{l.CHOOSE_KIND}</h3>

                    <div className='col-sm-12 col-md-8 col-lg-6 ' style={{margin: "auto"}}>
                        <div className='row'>
                            {
                                data.map(function(type, t) {
                                    return (
                                        <div className='configurHover col-6 col' key={t}
                                            onClick = { () => {
                                                
                                                const url = new URL(window.location);
                                                if(url && url.searchParams) {
                                                    url.searchParams.set('type', type.name);
                                                    url.searchParams.delete('line');
                                                    url.searchParams.delete('orientation');
                                                    url.searchParams.delete('size');
                                                    window.history.pushState(null, '', url.toString());
                                                }

                                                _this.setState({
                                                    type: t,
                                                    clickedTypeName: type.name
                                                })
                                            }}
                                        >
                                            <div className=''>
                                                <img src={type.configurImage} width="150"
                                                />
                                            </div>

                                            <div className='titleName'>
                                                {(type.name && l.configurator[type.name] ? l.configurator[type.name] : type.name)}
                                            </div>

                                            <div className=''>
                                                <span style={{marginLeft: "", fontSize: "13px"}}>
                                                    {l.FROM} € {type.from}
                                                </span>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            </div>
        )
    }

    render() {
        
        const { l, testMode, isLoggedIn, showPopupItemAdded, nameFPLC, nameGA, namePFN_CID } = this.props;     
        let isMac = this.state.isMac;


        // let progressBar = [l.TYPE, l.LINE, l.COVER, l.ORIENTATION, l.SIZE, l.PRODUCT]
        // console.log("progressBar:  ", progressBar);

        // let type;
        // let line;
        // let cover;
        // let orientation;
        // let size;
        // let product;
        // let bars;
        // if(progressBar) {
        //     for(let i = 0; i < progressBar.length; i++) {
        //         bars = progressBar[i];
        //         console.log(bars);
                
        //         if(bars === "Type") {
        //              type = bars;
        //             console.log("type:    ", type)
        //         }
        //         if(bars === "Productlijn") {
        //              line = bars;
        //             console.log("line:    ", line)
        //         }
        //         if(bars === "Cover") {
        //              cover = bars;
        //             console.log("cover :    ", cover)
        //         }
        //         if(bars === "Orientatie") {
        //              orientation = bars;
        //             console.log("orientatie :    ", orientation)
        //         }
        //         if(bars === "Afmeting") {
        //              size = bars;
        //             console.log("size :    ", size)
        //         }

        //     }
        // }

        // console.log(this.state.bar)
        // console.log("bars", bars)



        let showPopupLanguage = this.state.showPopupLanguage; 
        
        let configuratorNavActive = <img src='./assets/img/configurator_progress_bar/configurator-nav-active.svg' width={20} />
        let configuratorNavInactive = <img src='./assets/img/configurator_progress_bar/configurator-nav-inactive.svg' width={20} />
        let configuratorNavDone = <img src='./assets/img/configurator_progress_bar/configurator-nav-Done.svg' width={20} />
        let configuratorNavbarDone = <img id='NavbarDone' src='./assets/img/configurator_progress_bar/configurator-navbar-done.svg' />
        let configuratorNavbarActive = <img id='NavbarActive' src='./assets/img/configurator_progress_bar/configurator-navbar-active.svg' />
        let configuratorNavbarInactive = <img id='NavbarInactive' src='./assets/img/configurator_progress_bar/configurator-navbar-inactive.svg' />


        let configuratorNavbarDoneCover = <img id='NavbarDoneCover' src='./assets/img/configurator_progress_bar/configurator-navbar-done.svg' />
        let configuratorNavbarActiveCover = <img id='NavbarActiveCover' src='./assets/img/configurator_progress_bar/configurator-navbar-active.svg' />
        let configuratorNavbarInactiveCover = <img id='NavbarInactiveCover' src='./assets/img/configurator_progress_bar/configurator-navbar-inactive.svg' />

        return (
            <div className='container-lg'>
                <div className='row'>

                    <div className=' col' style={{paddingLeft: "0px"}}>
                        <h2 style={{lineHeight: "inherit", borderBottom: "3px solid #617873", width: "fit-content", marginBottom: "0"}} >
                            {l.CONFIGURATOR}
                        </h2>
                    </div>

                    <div className = 'col align-self-end text-end' style = {{paddingRight: "0px", marginBottom: "1%"}}>
                        {
                            isLoggedIn ?
                                <div>
                                    <button className = 'btn btn-dark ' 
                                        style = {{width: "auto", fontSize: "13px", marginRight: "2%"}}
                                        onClick = { () => {
                                            TaopixService().signOut()
                                            location.reload()
                                        }}
                                    >
                                        {l.LOGOUT}
                                    </button>

                                    <img src = "./assets/img/vlaggen/vlag-nl.svg" width={25} 
                                        style = {{cursor: "pointer"}}
                                        onClick = { () => {
                                            this.toggleShowPopupLanguage()
                                        }}
                                    />

                                </div>
                            :
                                <div>
                                    <button className = 'btn btn-dark ' 
                                        style = {{width: "auto", fontSize: "13px", marginRight: "2%"}}
                                        onClick = { () => {
                                            TaopixService().signIn()
                                        }}
                                    >
                                        {l.LOGIN}
                                    </button>

                                    <img src = "./assets/img/vlaggen/vlag-nl.svg" width={25} 
                                        style = {{cursor: "pointer"}}
                                        onClick = { () => {
                                            this.toggleShowPopupLanguage()
                                        }}
                                    />

                                </div>
                        }
                    </div> 

                    {
                        showPopupLanguage ?
                            <PopupContainer
                                showPopupLanguage = {true}
                                l = {l}
                                sendPopupNederlands = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=nl"
                                }}
                                sendPopupEngels = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=en"
                                }}
                                sendPopupDuits = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=de"
                                }}
                                sendPopupFrans = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=fr"
                                }}
                                closePopupKruisje = {this.toggleShowPopupLanguage.bind(this)}
                            />
                                : null
                    }
                    {
                        showPopupItemAdded ?
                            <PopupContainer
                                showPopupItemAdded = {true}
                                text = {l.PROJECT_ADDED_TO_CART}
                                sendPopup = { () => {
                                    location.href = "/" + l.route.CART
                                }}
                                closePopup = {this.props.parentCallback}
                                closePopupKruisje = {this.props.parentCallback}
                            />
                                : null
                    }
                        
                        <hr />



                    {/* {
                        <div className = 'd-flex justify-content-center'>
                            <div className = 'btn-group col-sm-12 col-md-10 '> 
                                {
                                    progressBar.map((pBar) => (
                                        <div className='progressText btn'>
                                            {pBar}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    } */}





                    {
                    
                        <div className = 'd-flex justify-content-center'>
                            <div className = 'btn-group col-sm-12 col-md-10 ' role="group" aria-label='Progress bar' style={{visibility: (showPopupLanguage || showPopupItemAdded ? "hidden" : "visible")}}>

                                <span className = "progressText btn" 
                                    style = {{ 
                                        backgroundColor: (this.state.type !== undefined ? "#white" : "white"),
                                        cursor: (this.state.type === undefined ? "default" : "pointer"),
                                        fontWeight: (this.state.type === undefined ? "bold" : "normal")
                                    }}  
                                    onClick = { () => { 
                                        this.setState({
                                            type: undefined,
                                            line: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedTypeName: undefined,
                                            clickedLineName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,

                                            clickedCoverName: undefined,
                                            cover: undefined
                                        })
                                    }}
                                >
                                    {l.TYPE}
                                </span>

                                <span className="progressText btn"
                                    style = {{
                                        backgroundColor: (this.state.line !== undefined ? "#white" : "white") ,
                                        cursor: (this.state.line === undefined ? "default" : "pointer"),
                                        fontWeight: (this.state.type !== undefined && this.state.line === undefined && this.state.orientation === undefined && this.state.size === undefined ? "bold" : "normal")
                                    }}
                                    onClick = { () => { 
                                        this.setState({
                                            line: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedLineName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,

                                            clickedCoverName: undefined,
                                            cover: undefined
                                        }) 
                                    }}
                                >
                                    {l.LINE}
                                </span>

                                {
                                    this.state.clickedLineName === "PREMIUM" &&
                                            <span className="progressText btn"
                                                style = {{
                                                    backgroundColor: (this.state.cover !== undefined ? "#white" : "white") ,
                                                    cursor: (this.state.cover === undefined ? "default" : "pointer"),
                                                    fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.cover === undefined && this.state.orientation === undefined && this.state.size === undefined ? "bold" : "normal")
                                                }}
                                                onClick = { () => { 
                                                    this.setState({
                                                        cover: undefined,
                                                        orientation: undefined,
                                                        size: undefined,

                                                        clickedCoverName: undefined,
                                                        clickedOrientationName: undefined,
                                                        clickedSizeName: undefined
                                                    }) 
                                                }}
                                            >
                                                {l.COVER}
                                            </span>
                                }
                                {
                                    this.state.clickedLineName === "PREMIUM" &&
                                        <span className="progressText btn"
                                            style = {{
                                                backgroundColor: (this.state.orientation !== undefined ? "#white" : "white"),
                                                cursor: (this.state.orientation === undefined ? "default" : "pointer"),
                                                fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.cover !== undefined && this.state.orientation === undefined && this.state.size === undefined ? "bold" : "normal")
                                            }}
                                            onClick = { () => { 
                                                this.setState({
                                                    orientation: undefined,
                                                    size: undefined,
                                                    clickedOrientationName: undefined,
                                                    clickedSizeName: undefined
                                                })
                                            }}
                                        >
                                            {l.ORIENTATION}
                                        </span>
                                }
                                {
                                    this.state.clickedLineName === "PREMIUM" &&
                                        <span className = "progressText btn" 
                                            style = {{
                                                backgroundColor: (this.state.size !== undefined ? "#white" : "white"),
                                                cursor: (this.state.size === undefined ? "default" : "pointer"),
                                                fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.cover !== undefined && this.state.orientation !== undefined && this.state.size === undefined ? "bold" : "normal")
                                            }}
                                            onClick = { () => {
                                                this.setState({
                                                    size: undefined,
                                                    clickedSizeName : undefined
                                                })
                                            }}
                                        >
                                            {l.SIZE}
                                        </span>
                                }
                                {
                                    this.state.clickedLineName === "PREMIUM" &&
                                        <span className = "progressText btn" 
                                            style = {{
                                                cursor: "default",
                                                fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.cover !== undefined && this.state.orientation !== undefined && this.state.size !== undefined ? "bold" : "normal")
                                            }}
                                        >
                                            {l.PRODUCT}
                                        </span>
                                }

                                {
                                    this.state.clickedLineName !== "PREMIUM" &&
                                        <span className="progressText btn"
                                            style = {{
                                                backgroundColor: (this.state.orientation !== undefined ? "#white" : "white"),
                                                cursor: (this.state.orientation === undefined ? "default" : "pointer"),
                                                fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.orientation === undefined && this.state.size === undefined ? "bold" : "normal")
                                            }}
                                            onClick = { () => { 
                                                this.setState({
                                                    orientation: undefined,
                                                    size: undefined,
                                                    clickedOrientationName: undefined,
                                                    clickedSizeName: undefined
                                                })
                                            }}
                                        >
                                            {l.ORIENTATION}
                                        </span>
                                }
                                {
                                    this.state.clickedLineName !== "PREMIUM" &&
                                        <span className = "progressText btn" 
                                            style = {{
                                                backgroundColor: (this.state.size !== undefined ? "#white" : "white"),
                                                cursor: (this.state.size === undefined ? "default" : "pointer"),
                                                fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.orientation !== undefined && this.state.size === undefined ? "bold" : "normal")
                                            }}
                                            onClick = { () => {
                                                this.setState({
                                                    size: undefined,
                                                    clickedSizeName : undefined
                                                })
                                            }}
                                        >
                                            {l.SIZE}
                                        </span>
                                }
                                {
                                    this.state.clickedLineName !== "PREMIUM" &&
                                        <span className = "progressText btn" 
                                            style = {{
                                                cursor: "default",
                                                fontWeight: (this.state.type !== undefined && this.state.line !== undefined && this.state.orientation !== undefined && this.state.size !== undefined ? "bold" : "normal")
                                            }}
                                        >
                                            {l.PRODUCT}
                                        </span>
                                }

                            </div>
                        </div>
                    }
                    <div>
                        {
                            this.state.type === undefined &&
                            <div>
                                <div className = 'd-flex justify-content-center'>
                                    <div className = 'col-sm-12 col-md-10 text-center' >
                                        {configuratorNavActive}
                                        {configuratorNavbarActive}
                                        {configuratorNavInactive}
                                        {configuratorNavbarInactive}
                                        {configuratorNavInactive}
                                        {configuratorNavbarInactive}
                                        {configuratorNavInactive}
                                        {configuratorNavbarInactive}
                                        {configuratorNavInactive}
                                    </div>
                                </div>
                                    {this.renderTypes(l)}
                                </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line === undefined  &&
                                <div>
                                    <div className = 'd-flex justify-content-center'>
                                        <div className = 'col-sm-12 col-md-10 text-center'>
                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                type: undefined,
                                                line: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                covers: undefined,

                                                clickedTypeName: undefined,
                                                clickedLineName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                                clickedCoverName: undefined,


                                            })}>
                                                {configuratorNavDone}
                                            </span>
                                                {configuratorNavbarDone}
                                                {configuratorNavActive}
                                                {configuratorNavbarActive}
                                                {configuratorNavInactive}
                                                {configuratorNavbarInactive}
                                                {configuratorNavInactive}
                                                {configuratorNavbarInactive}
                                                {configuratorNavInactive}
                                        </div>
                                    </div>

                                    {this.renderLines(l)}
                                </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover === undefined && this.state.clickedLineName === "PREMIUM" &&
                                <div>

                                    <div className='d-flex justify-content-center'>
                                        <div className='col-sm-12 col-md-10 text-center'>

                                            <span style = {{cursor: "pointer"}} onClick = {() => this.setState({
                                                type: undefined,
                                                line: undefined,
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedTypeName: undefined,
                                                clickedLineName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                                clickedCoverName: undefined
                                            })}>
                                                {configuratorNavDone}
                                            </span>
                                            
                                                {configuratorNavbarDoneCover}

                                            <span style={{cursor: "pointer"}} onClick = {() => this.setState({
                                                line: undefined,
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedLineName: undefined,
                                                clickedCoverName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarActiveCover}
                                                {configuratorNavActive}
                                                {configuratorNavbarInactiveCover}
                                                {configuratorNavInactive}
                                                {configuratorNavbarInactiveCover}
                                                {configuratorNavInactive}
                                                {configuratorNavbarInactiveCover}
                                                {configuratorNavInactive}
                                        </div>

                                    </div>
                                        {this.renderCovers(l)}
                                </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover !== undefined && this.state.orientation === undefined &&
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-sm-12 col-md-10 text-center'>

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                type: undefined,
                                                line: undefined,
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedTypeName: undefined,
                                                clickedLineName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                                clickedCoverName: undefined,
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDoneCover}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                line: undefined,
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedLineName: undefined,
                                                clickedCoverName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })} >
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDoneCover}

                                            <span style={{cursor: "pointer"}} onClick={() => this.setState({
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedCoverName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })}>
                                                {configuratorNavDone}
                                            </span>
                                                
                                                {configuratorNavbarActiveCover}
                                                {configuratorNavActive}
                                                {configuratorNavbarInactiveCover}
                                                {configuratorNavInactive}
                                                {configuratorNavbarInactiveCover}
                                                {configuratorNavInactive}

                                        </div>
                                    </div>
                                        {this.renderOrientations(l)}
                                </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover === undefined && this.state.orientation === undefined && this.state.clickedLineName === "PREMIUM_PLUS" &&
                            <div>
                                <div className = 'd-flex justify-content-center'>
                                    <div className = 'col-sm-12 col-md-10 text-center'>

                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            type: undefined,
                                            line: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            cover: undefined,
                                            clickedTypeName: undefined,
                                            clickedLineName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,
                                            clickedCoverName: undefined,
                                        })}>
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDone}

                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            line: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            cover: undefined,
                                            clickedLineName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,
                                            clickedCoverName: undefined,
                                        })} >
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDone}
                                            {configuratorNavActive}
                                            {configuratorNavbarActive}
                                            {configuratorNavInactive}
                                            {configuratorNavbarInactive}
                                            {configuratorNavInactive}
                                    </div> 
                                </div>

                                {this.renderOrientations(l)}
                            </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover === undefined && this.state.orientation === undefined && this.state.clickedLineName === "PROFESSIONAL" &&
                            <div>
                                <div className = 'd-flex justify-content-center'>
                                    <div className = 'col-sm-12 col-md-10 text-center'>
                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            type: undefined,
                                            line: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedTypeName: undefined,
                                            clickedLineName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined
                                        })}>
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDone}

                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            line: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedLineName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined
                                        })} >
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDone}
                                            {configuratorNavActive}
                                            {configuratorNavbarActive}
                                            {configuratorNavInactive}
                                            {configuratorNavbarInactive}
                                            {configuratorNavInactive}
                                    </div>
                                </div>

                                {this.renderOrientations(l)}
                            </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover === undefined && this.state.orientation === undefined && this.state.clickedTypeName === "CALENDERS" &&
                            <div>
                                <div className = 'd-flex justify-content-center'>
                                    <div className = 'col-sm-12 col-md-10 text-center'>
                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            type: undefined,
                                            line: undefined,
                                            cover: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedTypeName: undefined,
                                            clickedLineName: undefined,
                                            clickedCoverName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined
                                        })}>
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDone}

                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            line: undefined,
                                            cover: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedLineName: undefined,
                                            clickedCoverName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined
                                        })} >
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDone}
                                            {configuratorNavActive}
                                            {configuratorNavbarActive}
                                            {configuratorNavInactive}
                                            {configuratorNavbarInactive}
                                            {configuratorNavInactive}
                                    </div>
                                </div>

                                {this.renderOrientations(l)}
                            </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover === undefined && this.state.orientation !== undefined && this.state.size === undefined  &&
                                <div>
                                    <div className = 'd-flex justify-content-center'>
                                        <div className = 'col-sm-12 col-md-10 text-center'>
                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                type: undefined,
                                                line: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedTypeName: undefined,
                                                clickedLineName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDone}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                line: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedLineName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined
                                            })} >
                                                {configuratorNavDone}
                                            </span>

                                            {configuratorNavbarDone}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                orientation: undefined,
                                                size: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDone}
                                                {configuratorNavActive}
                                                {configuratorNavbarActive}
                                                {configuratorNavInactive}
                                        </div>
                                    </div>
                                    {this.renderSizes(l)}
                                </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.cover !== undefined && this.state.orientation !== undefined && this.state.size === undefined && this.state.clickedLineName === "PREMIUM" &&
                            <div>
                                <div className = 'd-flex justify-content-center'>
                                    <div className = 'col-sm-12 col-md-10 text-center'>
                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            type: undefined,
                                            line: undefined,
                                            cover: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedTypeName: undefined,
                                            clickedLineName: undefined,
                                            clickedCoverName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,
                                        })}>
                                            {configuratorNavDone}
                                        </span>

                                            {configuratorNavbarDoneCover}

                                        <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                            line: undefined,
                                            cover: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedLineName: undefined,
                                            clickedCoverName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,
                                        })} >
                                            {configuratorNavDone}
                                        </span>

                                        {configuratorNavbarDoneCover}

                                        <span style = {{cursor: "pointer"}} onClick = {() => this.setState({
                                            cover: undefined,
                                            orientation: undefined,
                                            size: undefined,
                                            clickedCoverName: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,
                                        })}
                                        >
                                            {configuratorNavDone}
                                        </span>

                                        {configuratorNavbarDoneCover}

                                        <span style={{cursor: "pointer"}} onClick={() => this.setState({
                                            orientation: undefined,
                                            size: undefined,
                                            clickedOrientationName: undefined,
                                            clickedSizeName: undefined,
                                        })}
                                        >
                                            {configuratorNavDone}
                                        </span>

                                        {configuratorNavbarActiveCover}
                                        {configuratorNavActive}
                                        {configuratorNavbarInactiveCover}
                                        {configuratorNavInactive}

                                    </div>
                                </div>

                                {this.renderSizes(l)}
                            </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.orientation !== undefined && this.state.size !== undefined && this.state.clickedLineName === "PREMIUM" &&
                                <div>
                                    <div className = 'd-flex justify-content-center'>
                                        <div className = 'col-sm-12 col-md-10 text-center'>

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                type: undefined,
                                                line: undefined,
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedTypeName: undefined,
                                                clickedLineName: undefined,
                                                clickedCoverName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDoneCover}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                line: undefined,
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedLineName: undefined,
                                                clickedCoverName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })} >
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDoneCover}

                                            <span style = {{cursor: "pointer"}} onClick = {() => this.setState({
                                                cover: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedCoverName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })}
                                            >
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDoneCover}

                                            <span style={{cursor: "pointer"}} onClick={() => this.setState({
                                                orientation: undefined,
                                                size: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined,
                                            })}
                                            >
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDoneCover}

                                            <span style={{cursor: "pointer"}} onClick={() => this.setState({
                                                size: undefined,
                                                clickedSizeName: undefined
                                            })}
                                            >
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarActiveCover}
                                                {configuratorNavActive}

                                        </div>
                                    </div>

                                    {this.renderProducts(l, testMode, nameFPLC, nameGA, namePFN_CID)}
                                </div>
                        }
                        {
                            this.state.type !== undefined && this.state.line !== undefined && this.state.orientation !== undefined && this.state.size !== undefined && this.state.clickedLineName !== "PREMIUM" &&
                                <div>
                                    <div className = 'd-flex justify-content-center'>
                                        <div className = 'col-sm-12 col-md-10 text-center'>
                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                    type: undefined,
                                                    line: undefined,
                                                    orientation: undefined,
                                                    size: undefined,
                                                    clickedTypeName: undefined,
                                                    clickedLineName: undefined,
                                                    clickedOrientationName: undefined,
                                                    clickedSizeName: undefined
                                                })}>
                                                    {configuratorNavDone}
                                                </span>

                                            {configuratorNavbarDone}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                line: undefined,
                                                orientation: undefined,
                                                size: undefined,
                                                clickedLineName: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined
                                            })} >
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDone}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                orientation: undefined,
                                                size: undefined,
                                                clickedOrientationName: undefined,
                                                clickedSizeName: undefined
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDone}

                                            <span style = {{cursor: "pointer"}} onClick = { () => this.setState({
                                                size: undefined,
                                                clickedSizeName : undefined
                                            })}>
                                                {configuratorNavDone}
                                            </span>

                                                {configuratorNavbarDone}
                                                {configuratorNavActive}
                                        </div>
                                    </div>

                                    {this.renderProducts(l, testMode, nameFPLC, nameGA, namePFN_CID)}
                                </div>
                        }
                    </div>

                    <div className = 'col-sm-10' style = {{margin: "auto", marginTop: "3%", visibility: (showPopupLanguage || showPopupItemAdded ? "hidden" : "visible") }}>
                        <hr />
                    </div>

                    <div className = 'col-sm-12 text-center'>

                        <h5>
                            {l.MAKE_YOUR_PHOTOBOOK}
                        </h5>

                        <button className = 'albumDesigner btn btn-primary' 
                            style = {{marginTop: "1%", fontSize: "13px"}}
                            onClick = { () => {
                                location.href = isMac ? "https://static.profotonet.nl/uploads/installers/Profotonet_Album_Designer.dmg" : "https://static.profotonet.nl/uploads/installers/Profotonet_Album_Designer.exe"
                            }}
                        >
                            {
                                isMac ? l.DOWNLOAD_FOR_MAC : l.DOWNLOAD_FOR_WINDOWS
                            }
                        </button>

                    </div>

                </div>
            </div>
        )
    }

}
export default Container;