var language = {
    route: {
        PROJECTS: "projets",
        CART: "panier",
        ACCOUNT: "compte",
        MY_PROJECTS: "mes-projets",
    },
    configurator: {
        PHOTOBOOKS: "Livres photo",
        CALENDERS: "Calendriers",
        PREMIUM: "Premium",
        PREMIUM_PLUS: "Premium Plus",
        PROFESSIONAL: "Professional",
        YEAR_CALENDER: "Calendrier annuel",
        BIRTHDAY_CALENDER: "Calendrier des anniversaires",
        STANDING: "Portrait",
        LANDSCAPE: "Paysage",
        SQUARE: "Carré",
        LEER_LINNEN_VINYL_COVER: "Couverture unie",
        FOTOCOVER_COVER: "Couverture photo",
        LASERTEKST_COVER: "Couverture gravée au laser",
        PLEXIGLAS_COVER: "Couverture plexiglas",
        FOTOCOVER_OP_LINNEN_COVER: "Couverture photo sur lin",
        EMBOSSING_COVER: "Couverture gaufree",
        DELUXE_COOPER_COVER: "Deluxe Cooper",
        DELUXE_DELON_COVER: "Deluxe Delon",
        DELUXE_NEWMAN_COVER: "Deluxe Newman",
        DELUXE_FOTOCOVER_COVER: "Deluxe Couverture photo",
        DELUXE_FRAME_LANDSCAPE_COVER: "Deluxe Cadre Paysage",
        DELUXE_FRAME_DETAIL_COVER: "Deluxe Petit Cadre",
        DELUXE_FRAME_TRIPLE_COVER: "Deluxe Cadre Triple",
        DELUXE_FRAME_DELUXE_COVER: "Deluxe Cadre Deluxe",
        BASIS_WIT_KAL: "Blanc basique",
        BLOK_WIT_KAL: "Blanc avec cases",
        CIRKEL_WIT_KAL: "Blanc motif arrondi",
        TRANSPARANT_ZWART_KAL: "Noir semi transparent",
        BASIS_ZWART_KAL: "Noir de base",
        VIERKANTE_FOTO_WIT_KAL: "Blanc photo carrée",
        VIERKANTE_FOTO_ZWART_KAL: "Noir photo carrée",
        TWEE_KOLOMMEN_KAL: "Deux colonnes",
        KOLOM_KAL: "Colonne",
        BASIS_KAL: "Basique",
        KOLOM_WIT_KAL: "Blanc avec colonne",
        KOLOM_ZWART_KAL: "Noir avec colonne",
        SOFTCOVER: "Softcover",
        FOTOCOVER: "Couverture photo",
        HARDCOVER: "Hardcover"
    },
    PROJECTS: "Projets",
    CART: "Panier",
    MY_PROJECTS: "Mes projets",
    ACCOUNT: "Compte",
    MY_ACCOUNT: "Mon compte",
    MY_CART: "Mon panier",
    QUESTION: "Des questions?",
    PHONENUMBER: "+31 (0) 10-2640410 ",
    TILL: "jusqu'à",
    TILLTIME: "17:00.",
    AVAILABLE: "joignable",
    CONFIGURATOR: "Configurateur",
    LOGIN: "Connexion",
    LOGOUT: "Se déconnecter",
    TO_SEE_YOUR_PROJECTS: "pour voir vos projets",
    TO_SEE_YOUR_ACCOUNT: "pour voir votre compte",
    NEW_PROJECT: "+ Nouveau projet",
    OPEN: "Ouvrir",
    DUPLICATE: "Dupliquer",
    CHOOSE_VERSION: "Choisissez une finition",
    CHOOSE_SIZE: "Choisissez une taille",
    CHOOSE_ORIENTATION: "Choisissez une orientation",
    CHOSEN: "Choisi:",
    CHOOSE_TYPE: "Choisissez le genre",
    BACK: "Revenir",
    CHOOSE_KIND: "Choisir le type",
    FROM: "à partir de",
    TYPE: "Taper",
    LINE: "Gamme de produits",
    ORIENTATION: "Orientation",
    SIZE: "Dimensions",
    PRODUCT: "Produit",

    CHOOSE_COVER: "Choisissez la couverture",

    MAKE_YOUR_PHOTOBOOK: "Créez des livres photo avec notre logiciel gratuit",
    DOWNLOAD_FOR_WINDOWS: "Télécharger pour Windows",
    DOWNLOAD_FOR_MAC: "Télécharger pour Mac",
    DIRECT_BEGINNEN: "Commencer maintenant",
    ADJUST: "Ajuster",
    ORDER: "Commander",
    DOWNLOAD: "Télécharger",
    MODIFY: "Modifier",
    CANCEL: "Annuler",
    YES: "Oui",
    NO: "Non",
    DUTCH: "Nederlands",
    BRITISH: "English",
    FRENCH: "Français",
    GERMAN: "Deutsch",
    MADE_ON: "Fait sur: ",
    REVIEWS_CUSTOMERS: "Commentaires 9,8 / 10 - 2907 les clients",
    DELIVERY_TIME: "Délai de livraison des livres photo 7 jours maximum",
    HOUSEHOLD_NAME: "Un nom familier parmi les photographes depuis plus de 40 ans",
    WINNER_QUALITY: "Gagnant du meilleur livre photo de qualité 2023",
    TO_SHOPPING_CART: "Voir mon panier",
    CLOSE: "Fermer",
    REMOVE_PROJECT_FROM_CART: "Voulez-vous retirer votre projet du panier d'achat?",
    PROJECT_ADDED_TO_CART: "Voulez ajouter votre projet dans votre panier.",
    RENAME_THE_PROJECT: "Changez le nom du projet.",
    NEW_NAME_FOR_THE_PROJECT: "Introduisez un nouveau nom pour votre projet.",
    DELETE_PROJECT: "Voulez-vous supprimer le projet de façon définitive?",
    COVER: "Cover",
    NOWHITELABEL: "avec profotonet logo"
    
}

export default language;