import cookies from 'js-cookie';

//Define session storage (only works as long as you don't refresh)
var sessionStorage = {};

//Check if localStorage is set
function validateLocalStorage() {
  try {
    if (typeof window.localStorage !== 'undefined' && typeof localStorage !== 'undefined') {
        localStorage.setItem('feature_test', 'yes');
        if (localStorage.getItem('feature_test') === 'yes') {
          localStorage.removeItem('feature_test');
          return true;
        } else {
          return false;
        }
    } else {
      return false;
    }
  } catch(e) {
    return false;
  }
}

//Check if cookie is available
function validateCookies() {
  if (navigator && navigator.cookieEnabled && document && typeof document.cookie !== 'null') {
    return true;
  } else {
    return false;
  }
}

//Cross domain cookes are handled by setting an img element with a src directed to a custom script running on the website itself (built by Happy)
function handleCrossDomainCookies(_id) {
  ["nl", "be", "com"].map(function(extension) {
    addPfncImageToBody(extension, _id);
  });
}

//Add img element to handle cross domain cookies
function addPfncImageToBody(extension, _id) {

  let elementId = `pfnc_${extension}`;

  //First remove element if already set
  let removeElement = document.getElementById(elementId);
  if(removeElement && removeElement.parentNode) {
    removeElement.parentNode.removeChild(removeElement);
  }

  //Set the new image
  let img = document.createElement("img");
  img.setAttribute("id", elementId);
  img.setAttribute("src", `https://www.profotonet.${extension}/pfn-cart.php?pfnc=${_id}`);
  img.setAttribute("width", 1);
  img.setAttribute("height", 1);
  img.setAttribute("role", "presentation");
  img.setAttribute("alt", "");
  img.style.display = "none !important"; 
  document.getElementById("root").appendChild(img);
}

//Create the storage helper
var storageHelper = {
  setItem: function(key, value) {
    if(validateCookies()) {

      //Remove it from the localStorage
      if(localStorage.getItem(key)) {
        localStorage.removeItem(key);
      }

      if(window.location && window.location.protocol.indexOf("https") > -1) {
        if(window.location.hostname && window.location.hostname.indexOf("profotonet.com") > -1) {
          cookies.set(key, value, {secure: true, domain: ".profotonet.com", sameSite: 'none'});
        } else {
          cookies.set(key, value, {secure: true, domain: ".profotonet.nl", sameSite: 'none'});
        }

        //Add cross domain cookies for cart
        if(key == "pfnc" && value && value != "") {
          handleCrossDomainCookies(value);
        }

      } else {
        cookies.set(key, value);
      }
    } else if(validateLocalStorage()) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage[key] = value;
    }
  },
  setSessionItem: function(key, value) {
    sessionStorage[key] = value;
  },
  getItem: function(key) {
    if(validateCookies() && !localStorage.getItem(key)) {
      return cookies.get(key);
    } else if(validateLocalStorage()) {
      return localStorage.getItem(key);
    } else {
      return (sessionStorage[key] || undefined);
    }
  },
  getSessionItem: function(key) {
    return (sessionStorage[key] || undefined);
  },
  removeItem: function(key) {
    if (validateCookies()) {

      cookies.remove(key);

      //Remove on https is more complicated
      if(window.location && window.location.protocol.indexOf("https") > -1) {
        if(window.location.hostname && window.location.hostname.indexOf("profotonet.com") > -1) {
          cookies.remove(key, {secure: true, domain: ".profotonet.com", sameSite: 'none'});
        } else {
          cookies.remove(key, {secure: true, domain: ".profotonet.nl", sameSite: 'none'});
        }

        //Remove cross domain cookies for cart
        if(key == "pfnc") {
          handleCrossDomainCookies("false");
        }

      }
    } else if(validateLocalStorage()) {
      localStorage.removeItem(key);
    } else {
      delete sessionStorage[key];
    }
  },
  removeSessionItem: function(key) {
    delete sessionStorage[key];
  }
}

export default storageHelper;
