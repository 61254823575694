var language = {
    route: {
        PROJECTS: "projecten",
        CART: "winkelwagen",
        ACCOUNT: "account",
        MY_PROJECTS: "mijn-projecten",
    },
    configurator: {
        PHOTOBOOKS: "Fotoboeken",
        CALENDERS: "Kalenders",
        PREMIUM: "Premium",
        PREMIUM_PLUS: "Premium Plus",
        PROFESSIONAL: "Professional",
        YEAR_CALENDER: "Jaarkalender",
        BIRTHDAY_CALENDER: "Verjaardagskalender",
        STANDING: "Staand",
        LANDSCAPE: "Liggend",
        SQUARE: "Vierkant",
        LEER_LINNEN_VINYL_COVER: "Effen leer/linnen cover",
        FOTOCOVER_COVER: "Fotocover",
        LASERTEKST_COVER: "Lasergegraveerde cover",
        PLEXIGLAS_COVER: "Plexiglas cover",
        FOTOCOVER_OP_LINNEN_COVER: "Fotocover op linnen",
        EMBOSSING_COVER: "Embossing cover",
        DELUXE_COOPER_COVER: "Deluxe Cooper",
        DELUXE_DELON_COVER: "Deluxe Delon",
        DELUXE_NEWMAN_COVER: "Deluxe Newman",
        DELUXE_FOTOCOVER_COVER: "Deluxe Fotocover",
        DELUXE_FRAME_LANDSCAPE_COVER: "Deluxe Frame Landscape",
        DELUXE_FRAME_DETAIL_COVER: "Deluxe Frame Detail",
        DELUXE_FRAME_TRIPLE_COVER: "Deluxe Frame Triple",
        DELUXE_FRAME_DELUXE_COVER: "Deluxe Frame Deluxe",
        BASIS_WIT_KAL: "Basis wit",
        BLOK_WIT_KAL: "Blok wit",
        CIRKEL_WIT_KAL: "Cirkel wit",
        TRANSPARANT_ZWART_KAL: "Transparant zwart",
        BASIS_ZWART_KAL: "Basis zwart",
        VIERKANTE_FOTO_WIT_KAL: "Vierkante foto wit",
        VIERKANTE_FOTO_ZWART_KAL: "Vierkante foto zwart",
        TWEE_KOLOMMEN_KAL: "Twee kolommen",
        KOLOM_KAL: "Kolom",
        BASIS_KAL: "Basis",
        KOLOM_WIT_KAL: "Kolom wit",
        KOLOM_ZWART_KAL: "Kolom zwart",
        SOFTCOVER: "Softcover",
        FOTOCOVER: "Fotocover",
        HARDCOVER: "Hardcover"
    },
    PROJECTS: "Projecten",
    CART: "Winkelwagen",
    MY_PROJECTS: "Mijn projecten",
    ACCOUNT: "Account",
    MY_ACCOUNT: "Mijn account",
    MY_CART: "Mijn winkelwagen",
    QUESTION: "Vragen?",
    PHONENUMBER: "010-2640410",
    TILL: "tot",
    TILLTIME: "17:00",
    AVAILABLE: "bereikbaar.",
    CONFIGURATOR: "Configurator",
    LOGIN: "Inloggen",
    LOGOUT: "Uitloggen",
    TO_SEE_YOUR_PROJECTS: "om je projecten te zien",
    TO_SEE_YOUR_ACCOUNT: "om je account te zien",
    NEW_PROJECT: "+ Nieuw project",
    OPEN: "Openen",
    DUPLICATE: "Dupliceren",
    CHOOSE_VERSION: "Kies uitvoering",
    CHOOSE_SIZE: "Kies formaat",
    CHOOSE_ORIENTATION: "Kies oriëntatie",
    CHOSEN: "Gekozen:",
    CHOOSE_TYPE: "Kies type",
    BACK: "Terug",
    CHOOSE_KIND: "Kies soort",
    FROM: "vanaf",
    TYPE: "Type",
    LINE: "Productlijn",
    ORIENTATION: "Orientatie",
    SIZE: "Afmeting",
    PRODUCT: "Product",

    CHOOSE_COVER: "Kies cover",

    MAKE_YOUR_PHOTOBOOK: "Of maak je fotoboek met onze gratis offline software",
    DOWNLOAD_FOR_WINDOWS: "Download voor Windows",
    DOWNLOAD_FOR_MAC: "Download voor Mac",
    DIRECT_BEGINNEN: "Direct beginnen",
    ADJUST: "Aanpassen",
    ORDER: "Bestellen",
    DOWNLOAD: "Download",
    MODIFY: "Wijzigen",
    CANCEL: "Annuleren",
    YES: "Ja",
    NO: "Nee",
    DUTCH: "Nederlands",
    BRITISH: "English",
    FRENCH: "Français",
    GERMAN: "Deutsch",
    MADE_ON: "Aangemaakt op: ",
    REVIEWS_CUSTOMERS: "Beoordelingen 9,8 / 10 - 2907 klanten",
    DELIVERY_TIME: "Levertijd fotoboeken max. 7 dagen",
    HOUSEHOLD_NAME: "Al meer dan 40 jaar een begrip onder fotografen",
    WINNER_QUALITY: "Winnaar beste kwaliteit fotoboek 2023",
    TO_SHOPPING_CART: "Naar winkelwagen",
    CLOSE: "Sluiten",
    REMOVE_PROJECT_FROM_CART: "Wil je het project verwijderen uit de winkelwagen?",
    PROJECT_ADDED_TO_CART: "Project is toegevoegd aan de winkelwagen.",
    RENAME_THE_PROJECT: "Wijzig de naam van het project.",
    NEW_NAME_FOR_THE_PROJECT: "Vul een nieuwe naam in voor het project.",
    DELETE_PROJECT: "Wil je het project permanent verwijderen?",
    COVER: "Cover",
    NOWHITELABEL: "met profotonet logo"

}

export default language;