import React, {Component} from 'react';
import {TaopixObservable, TaopixService} from '../services/taopix-service';

import PopupContainer from './popup-container';

class Container extends Component {

    constructor(props, context) {
        super(props, context);

        this.state={
            basketItems: [],
            showPopupRename: false,
            pNewProjectName: null,
            pProjectRef: null,
            pProjectName: null,
            shoppingCartURL: undefined,
            showPopupDelete: false,
            showPopupLanguage: false
        }

        TaopixObservable.subscribe(this);
        TaopixService().getBasket();
        TaopixService().checkout();
    }

    handleNotification(type, data) {
        if(type == "tpxHighLevelGetBasketContentsView") {
            if(data?.basketItems && data?.basketItems.length > 0) {
                this.setState({
                    basketItems: data.basketItems
                });
            } else {
                this.setState({
                    basketItems: []
                });
            }
        }

        if(type == "tpxHighLevelCheckoutView") {
            if(data?.shoppingCartURL && data?.shoppingCartURL.length > 0) {
                this.setState({
                    shoppingCartURL: data.shoppingCartURL
                });
            } else {
                this.setState({
                    shoppingCartURL: []
                });
            }
        }
        // console.log("HANDLE NOTIFICATION", type, data);
    }

    componentWillUnmount() {
        TaopixObservable.unsubscribe(this);
        //console.log("Unmount");
    }

    sendData(basketItemsTotal) {
        this.props.parentCallback(basketItemsTotal)
    }

    renderLayoutName(projectItem) {
        let layoutname = projectItem.layoutname;
        let text;

        if(layoutname && layoutname.indexOf("<p>") > -1) {
            let splitLayoutName = layoutname.split("<p>");
            if(splitLayoutName.length > 0) {
                for(let l = 0; l < splitLayoutName.length; l++) {
                    let splitLayoutNameEntry = splitLayoutName[l];

                    let languageCode = splitLayoutNameEntry.substring(0, 2);
                    let entryName = splitLayoutNameEntry.slice(3);

                    if(languageCode == this.props.languageCode) {
                        text = entryName;
                        break;
                    }
                }
            }
        } else {
            text = layoutname; 
        }

        return(
            <span>
                {text}
            </span>
        )
    }

    togglePopupDelete(pProjectRef) {
        this.setState({
            pProjectRef: pProjectRef,
            showPopupDelete: !this.state.showPopupDelete
        })
    }

    toggleShowPopupLanguage() {
        this.setState({
            showPopupLanguage: !this.state.showPopupLanguage
        })
    }

    render() {

        const l = this.props.l;
        let basketItems = this.state.basketItems;
        let isLoggedIn = this.props.isLoggedIn;
        let shoppingCartURL = this.state.shoppingCartURL;
        let pProjectRef = this.state.pProjectRef;
        let showPopupDelete = this.state.showPopupDelete; 
        let showPopupLanguage = this.state.showPopupLanguage; 
        let layoutcode;

        return (
            <div className='container'>
                <div className='row'>

                <div className='col' style={{paddingLeft: "0px"}}>
                    <h2 style={{lineHeight: "inherit", borderBottom: "3px solid #617873", width: "fit-content", marginBottom: "0"}} >
                        {l.CART}
                    </h2>
                </div>

                <div className = 'col align-self-end text-end' style = {{paddingRight: "0px", marginBottom: "1%"}}>
                    {
                        isLoggedIn ?

                            <div>
                                <button className = 'btn btn-dark ' 
                                    style = {{width: "auto", fontSize: "13px", marginRight: "2%"}}
                                    onClick = { () => {
                                        TaopixService().signOut()
                                        location.reload()
                                    }}
                                >
                                    {l.LOGOUT}
                                </button>

                                <img src = "./assets/img/vlaggen/vlag-nl.svg" width={25} 
                                    style = {{cursor: "pointer"}}
                                    onClick={ () => {
                                        this.toggleShowPopupLanguage()
                                    }}
                                />
                            </div>
                        :
                            <div>
                                <button className = 'btn btn-dark ' 
                                    style = {{width: "auto", fontSize: "13px", marginRight: "2%"}}
                                    onClick = { () => {
                                        TaopixService().signIn()
                                    }}
                                >
                                    {l.LOGIN}
                                </button>

                                <img src = "./assets/img/vlaggen/vlag-nl.svg" width={25} 
                                    style = {{cursor: "pointer"}}
                                    onClick={ () => {
                                        this.toggleShowPopupLanguage()
                                    }}
                                />
                            </div>
                    }
                </div> 
                    <hr />
                        {
                            showPopupDelete ?
                                <PopupContainer
                                    showPopupDelete = {true}
                                    l = {l}
                                    text = {l.REMOVE_PROJECT_FROM_CART}
                                    sendPopup = { () => {
                                        TaopixService().removeBasketItem(pProjectRef)
                                        location.reload()
                                    }}
                                    closePopup = {this.togglePopupDelete.bind(this)}
                                    closePopupKruisje = {this.togglePopupDelete.bind(this)}
                                />
                                    : null
                        }
                        {
                        showPopupLanguage ?
                            <PopupContainer
                                showPopupLanguage = {true}
                                l = {l}
                                sendPopupNederlands = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=nl"
                                }}
                                sendPopupEngels = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=en"
                                }}
                                sendPopupDuits = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=de"
                                }}
                                sendPopupFrans = { () => {
                                    location.href = "https://designer.profotonet.com/?lang=fr"
                                }}
                                closePopupKruisje = {this.toggleShowPopupLanguage.bind(this)}
                            />
                                : null
                        }
                        {
                            isLoggedIn ?
                                <div className='' style={{paddingLeft: "0px"}}>
                                    {basketItems.map((p, key) => (
                                        <div className='row' key={key} style={{marginTop: "2%"}}>

                                            <div className='col-5 col-sm-5 col-md-4 col-lg-3 row align-items-center' style={{maxWidth: "250px"}}>
                                                    <img style={{width: "194px", height: "170px", objectFit: "cover"}} src={p.projectpreviewthumbnail} />
                                            </div>

                                            <div className='col-6 col-sm-6 col-md-7 col-lg-6 align-self-center'>

                                                <div className='d-flex'>
                                                    <b style={{fontSize: "22px", fontFamily: "amasis-mtstd-medium"}}>
                                                        {p.projectname}
                                                    </b>
                                                </div>

                                                <div style={{marginBottom: "3%"}}>
                                                    {
                                                        this.renderLayoutName(p)
                                                    }
                                                    <span> | </span>
                                                    {
                                                        layoutcode = p.layoutcode.split("_").splice(1,1)
                                                    }
                                                </div>

                                                <button className='colorbtn btn btn-dark' style={{marginRight: "2%", marginTop: "3%", width: "auto", maxWidth: "75%", hover: "#8dbdb8"}} onClick={() => {
                                                    TaopixService().editProject(p.projectref)
                                                }}>
                                                    {l.ADJUST}
                                                </button>

                                            </div>

                                            <div className='col-1 col-sm-1 col-md-1 col-lg-2 align-self-center' style={{padding: "0 0"}}>
                                                <div className='row'>
                                                    <img className='trashbin' style={{cursor: "pointer", marginLeft: "auto", maxWidth: "32px", maxHeight: "32px", cursor: "pointer", paddingBottom: "5px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "5px"}} 
                                                        src="./assets/img/icons/trash-bin.svg" 
                                                        onMouseOver={e => (e.currentTarget.src = "./assets/img/icons/trash-bin-hover.svg")}
                                                        onMouseOut= {e => (e.currentTarget.src = "./assets/img/icons/trash-bin.svg")}
                                                        onClick = { () => {
                                                            pProjectRef = p.projectref;
                                                            this.togglePopupDelete(pProjectRef);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                    
                                    <div className='col-md-8 text-center' style={{margin: "auto", marginTop: "5%", visibility: (showPopupDelete || showPopupLanguage ? "hidden" : "visible" ) }}>
                                        <hr />
                                    </div>

                                    {
                                        basketItems.length > 0 ?
                                            <div className='row text-end'>
                                                <div className='col-md-12'>
                                                    <a className='' href= {shoppingCartURL}>
                                                        <button className='btn btn-dark' style={{marginTop: "2%"}} >
                                                            {l.ORDER}
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                            : null
                                    }

                                    {/* <div className='row text-center'>
                                        <div className='col-md-12'>
                                            Er is niks in uw winkelwagen
                                        </div>
                                    </div> */}
                    
                                </div>
                            :
                                <div>

                                    <div className='text-center' style={{marginTop: "5%", marginBottom: "5%"}}>
                                        <button className='btn btn-dark' onClick={() => {
                                            TaopixService().signIn();
                                        }}>
                                            {l.LOGIN}
                                        </button>
                                        <span className='' style={{marginLeft: "1%"}}>
                                            {l.TO_SEE_YOUR_PROJECTS}
                                        </span>
                                    </div>
                                    
                                    <div className='col-md-8' style={{margin: "auto", visibility: (showPopupDelete || showPopupLanguage ? "hidden" : "visible") }}>
                                        <hr />
                                    </div>

                                </div>
                        }
                </div>
            </div>
        )
    }

}
export default Container;