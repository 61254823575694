import React, {Component} from 'react';
import Router, {route, Link} from 'preact-router';

import Storage from '../utils/Storage';
import Url from '../utils/Url';
import ReactTagManager from '../utils/gtm';
// import TagManager from '../utils/gtm';

import ConfiguratorContainer from './configurator-container';
import ProjectsContainer from './projects-container';
import CartContainer from './cart-container';
import AccountContainer from "./account-container";
import PopupContainer from "./popup-container";

import {TaopixObservable, TaopixService} from '../services/taopix-service';

function getPathName() {
    // let pathname = window.location.pathname.split("/")[1];
    // let pathnames = pathname.toUpperCase()[0];
    // let str = pathname.substring(1);
    // let pathName = pathnames + str;

    // return (pathName ? pathName.toLowerCase() : "");
    
    let pathName = window.location.pathname;
    return (pathName ? pathName : "")
}

class Container extends Component {

    constructor(props, context) {
        super(props, context);

        //Language
        let lang;
        let queryParams = new URLSearchParams(window.location.search);
        if(queryParams) {
            lang = queryParams.get("lang");
            if(lang) {
                Storage.setItem("pfnmlbl", lang);
            }
        }
        if(!lang) {
            lang = Storage.getItem("pfnmlbl");
        }
        if(!lang) {
            lang = "nl";
        }

        //Load the languageObject
        const languageObject = require("../languages/"+lang).default;

        //Set testMode based on the hostname
        let testMode = false;
        if(window?.location?.hostname?.includes("mlbasket.profotonet.nl")) {
            testMode = true;
        }

        //Init tagmanager
        if(!testMode) {
            ReactTagManager.initialize({gtmId: "GTM-TD4H4Z2"});
        }

        let specialLoadingState = false
        if (window?.location?.pathname?.includes("hlcreate")) {
            specialLoadingState = true;
        }

        //State
        this.state = {
            isLoggedIn: false,
            languageCode: lang,
            languageObject: languageObject,
            basketItems: 0,
            basketItemsTotal: 0,
            specialLoadingState: specialLoadingState,
            loading: (specialLoadingState === true ? true : false),
            error: "",
            success: "",
            myAccountURL: undefined,
            testMode: testMode,
            showPopupItemAdded: false,

            activeRoute: getPathName(),

            nameGA: null,
            nameFPLC: null,
            namePFN_CID: null,
            maintenanceMode: false
        }

        TaopixObservable.subscribe(this);
        TaopixService().init(testMode);
        TaopixService().getBasket();
        TaopixService().getAccount();
        this.checkCookie();
    }

    //TaopixObervable implementation
    handleNotification(type, data) {

        //Handle signedIn
        if(type == "tpxHighLevelLoggedInStatusCallBack") {
            if(data?.pIsSignedIn === 1) {
                this.setState({
                    isLoggedIn: true
                });
            } else {
                this.setState({
                    isLoggedIn: false
                });
            }
        }

        if(type == "tpxHighLevelGetBasketContentsView") {
            if(data?.basketItems && data?.basketItems.length > 0) {
                this.setState({
                    basketItems: data.basketItems.length
                });
            } else {
                this.setState({
                    basketItems: []
                });
            }
        }

        if(type == "tpxHighLevelMyAccountInitView") {
            if(data?.myAccountURL && data?.myAccountURL.length > 0) {
                this.setState({
                    myAccountURL: data.myAccountURL
                });
            } else {
                this.setState({
                    myAccountURL: undefined
                });
            }
        }

        if(type == "tpxStartLoading" ) {
            this.setState({
                loading: true
            })
        }

        if(type == "tpxEndLoading" ) {
            if(!this.state.specialLoadingState) {
                this.setState({
                    loading: false
                })
            }
        }

        // https://designer.profotonet.com
        // https://mlbasket.profotonet.nl
        if (type == "tpxAddedToCart" && location.origin === "https://designer.profotonet.com" ) {
            if(data?.newBasketCount > data?.gOldBasketCount) {
                this.setState({
                    showPopupItemAdded: true
                })
            } else {
                this.setState({
                    showPopupItemAdded: false
                })
            }
        }

        // console.log("HANDLE NOTIFICATION", type, data);

    }

    componentDidUpdate(prevProps) {
        // console.log(prevProps.location, this.props.location)
    }

    componentWillUnmount() {

        TaopixObservable.unsubscribe(this);
        //console.log("Unmount");
    }

    handleCallback() {
        this.setState({
            showPopupItemAdded: false
        })
    }

    checkCookie() {

        let cookie = document.cookie;
        // let cookie = "_ga_1234567=1654566;" + "_ga=121339;" + " FPLC=1;" + " sdsd=5;" + "PFN_CID=33cc2189-28e2-48fc-831e-c46e3444cff1"
        // let cookie = "_ga_1234567=1654566;" + " FPLC=1;" + " sdsd=5;" + "PFN_CID=33cc2189-28e2-48fc-831e-c46e3444cff1"

        if(cookie && (cookie.indexOf("PFN_CID") > -1 || cookie.indexOf("_ga") > -1) ){
            let newCookie = cookie.split(";");
            if(newCookie.length > 0) {
                for(let i = 0; i < newCookie.length; i++) {
                    let c = newCookie[i].trimStart();

                    // let cookieNameFPCL = c.substring(0, 4);
                    // let cookienameGA = c.substring(0,3);

                    if(c.split("=")[0] == "_ga") {
                        this.setState({
                            nameGA: c
                        })
                    }

                    if(c.split("=")[0] == "PFN_CID") {
                        this.setState({
                            namePFN_CID: c
                        })
                    }

                    if(c.split("=")[0] == "FPLC") {
                        this.setState({
                            nameFPLC: c
                        })
                    }
                }
            }
        }
    }

    render() {

        const {languageObject,languageCode} = this.state;
        let isLoggedIn = this.state.isLoggedIn;
        let loading = this.state.loading;
        let error = this.state.error;
        let success = this.state.success;
        let myAccountURL = this.state.myAccountURL;
        let showPopupItemAdded = this.state.showPopupItemAdded;

        let _this = this;
        let activeRoute = this.state.activeRoute;

        let nameGA = this.state.nameGA;
        let nameFPLC = this.state.nameFPLC;
        let namePFN_CID = this.state.namePFN_CID;
        let maintenanceMode = this.state.maintenanceMode;

        return (
            <div className='wrapper'>
                {
                    this.state.loading &&
                        <div id="spinner" className="spinner-wrapper">
                            <div className="spinner">
                                <img className="logo" style={{maxHeight: "50px"}} src="./assets/logo.svg" />
                                <br />
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                }
                {
                    error && error != "" &&
                        <div className="error">
                            {error}
                        </div>  
                }
                {
                    success && success != "" &&
                        <div className="success">
                            {success}
                        </div>
                }
                {
                    maintenanceMode ?
                        <div className='row' style={{ marginTop: "100px" }}>
                            <div className='col-sm-3'>
                            </div>
                            <div className='col-sm-6 text-center' style={{ width: "50%"}}>
                                <h4><b>Online Designer tijdelijk niet toegankelijk</b></h4>
                                <h4 style={{ marginTop: "30px"}}>
                                    <p>Vanwege een geplande systeemupgrade is onze Online Designer momenteel niet toegankelijk.</p> 
                                    <p>Gedurende deze periode kunnen fotoboeken en kalenders niet via de online tool worden besteld.</p>
                                    <p>Naar verwachting zal de upgrade de gehele dag in beslag nemen. Zodra deze is voltooid, kun je weer gebruikmaken van de Online Designer.</p>
                                    <p>De upgrade heeft geen invloed op de functionaliteit, maar richt zich op het verbeteren van onze servers voor toekomstige prestaties.</p>
                                    <p>Onze excuses voor het ongemak.</p>
                                    <p>Het is mogelijk een nieuw project te beginnen via de Desktop Album Designer. Bestellen kan vandaag niet maar wel weer nadat de upgrade is afgerond.</p>
                                    <p>Weet wel dat wanneer je een project begint in de Desktop Designer dat deze niet beschikbaar zal zijn in de Online Designer. </p>
                                    <p>Het project dat je begint in de Desktop Designer kan enkel via dit programma besteld worden.</p>
                                    <p>Download de Desktop Album Designer hier:</p>
                                 </h4>
                                
                                <div style={{ marginTop: "30px"}} >
                                    <button className='btn btn-secondary' onClick={() => {location.href = "https://static.profotonet.nl/uploads/installers/Profotonet_Album_Designer.exe" }} style={{ width: "100px" }} >Windows</button>
                                    <button className='btn btn-secondary' onClick={() => {location.href = "https://static.profotonet.nl/uploads/installers/Profotonet_Album_Designer.dmg" }} style={{ width: "100px", marginLeft: "5px" }} >Mac</button>
                                </div>
                                
                                <img className="logo" style={{maxHeight: "50px", marginTop: "20px"}} src="./assets/logo.svg" />
                            </div>
                            <div className='col-sm-3'>
                            </div>
                        </div>
                    :

                    // validSurvey &&
                    <div>
                        <div className='container'>
                            <div className='d-none d-lg-block'>
                                <div className='row' style={{height: "auto", fontSize: "12px", textAlign: "center", marginTop: "5px"}}>
                                    <div className='col-sm-4 col-md-3' style={{padding: "0"}}>
                                        <a href="https://www.ekomi.nl/klantenmeningen-wwwprofotonetnl.html" className="headerReviews" rel="noopener nofollow" target="_blank" style={{color: "black", textDecoration: "none"}}>
                                            <svg className="headerStar" viewBox="0 2 20 20" >
                                                <path fill="#8DBDB8" d="M9.38843309,1.58147627 L11.2377447,7.31871734 L17.3197185,7.31871734 L12.3790309,11.1151412 L14.1215355,16.9556718 L9.23041429,13.3454075 L4.24468185,16.8224323 L6.14443317,11.0242293 L1.30932596,7.31871734 L7.38479612,7.31871734 L9.38843309,1.58147627 Z"></path>
                                            </svg>
                                            <svg className="headerStar" viewBox="0 2 20 20" >
                                                <path fill="#8DBDB8" d="M9.38843309,1.58147627 L11.2377447,7.31871734 L17.3197185,7.31871734 L12.3790309,11.1151412 L14.1215355,16.9556718 L9.23041429,13.3454075 L4.24468185,16.8224323 L6.14443317,11.0242293 L1.30932596,7.31871734 L7.38479612,7.31871734 L9.38843309,1.58147627 Z"></path>
                                            </svg>
                                            <svg className="headerStar" viewBox="0 2 20 20" >
                                                <path fill="#8DBDB8" d="M9.38843309,1.58147627 L11.2377447,7.31871734 L17.3197185,7.31871734 L12.3790309,11.1151412 L14.1215355,16.9556718 L9.23041429,13.3454075 L4.24468185,16.8224323 L6.14443317,11.0242293 L1.30932596,7.31871734 L7.38479612,7.31871734 L9.38843309,1.58147627 Z"></path>
                                            </svg>
                                            <svg className="headerStar" viewBox="0 2 20 20" >
                                                <path fill="#8DBDB8" d="M9.38843309,1.58147627 L11.2377447,7.31871734 L17.3197185,7.31871734 L12.3790309,11.1151412 L14.1215355,16.9556718 L9.23041429,13.3454075 L4.24468185,16.8224323 L6.14443317,11.0242293 L1.30932596,7.31871734 L7.38479612,7.31871734 L9.38843309,1.58147627 Z"></path>
                                            </svg>
                                            <svg className="headerStar" viewBox="0 2 20 20" >
                                                <path fill="#8DBDB8" d="M9.38843309,1.58147627 L11.2377447,7.31871734 L17.3197185,7.31871734 L12.3790309,11.1151412 L14.1215355,16.9556718 L9.23041429,13.3454075 L4.24468185,16.8224323 L6.14443317,11.0242293 L1.30932596,7.31871734 L7.38479612,7.31871734 L9.38843309,1.58147627 Z"></path>
                                            </svg>
                                            {languageObject.REVIEWS_CUSTOMERS}
                                        </a>
                                    </div>
                                    <div className='col-sm-4 col-md-3'>
                                        <div className='d-inline-flex'>
                                            <a href="https://www.profotonet.nl/blog/product-informatie/levertijden" rel="noopener" target="_blank" aria-label="Levertijd fotoboeken max. 7 dagen" style={{textDecoration: "none", color: "black"}}>
                                                <img src="./assets/img/icons/snelle_levering.svg" alt="" width="20" height="20" style={{marginRight: "5px", marginBottom: "2px"}} />
                                                {languageObject.DELIVERY_TIME}
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-3 moreThenFourty'>
                                        <div className='d-inline-flex'>
                                            <img src="./assets/img/icons/topkwaliteit.svg" alt="" width="20" height="20" style={{marginRight: "5px"}} />
                                            {languageObject.HOUSEHOLD_NAME}
                                        </div>
                                    </div>
                                    <div className='col-sm-3 col-md-3'>
                                        <div className='d-inline-flex'>
                                            <img src="./assets/img/icons/topkwaliteit.svg" alt="" width="20" height="20" style={{marginRight: "5px"}} />
                                            {languageObject.WINNER_QUALITY}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='container-fluid taskbar' style={{backgroundColor: "#f2f5f4"}}>
                            <div className='container' >
                                <div className='row' >

                                    {/* logo */}
                                    <div className='navbarLogo col-6 col-sm-6 col-md-4 align-self-center'>
                                        <div className='' style={{marginTop: "8px"}}>
                                            <img className='headerLogo' src="./assets/logo.svg" alt="logo" 
                                                style = {{maxHeight: "50px", cursor: "pointer"}} 
                                                onClick = { () => {
                                                    if (this.state.languageCode === "nl") {
                                                        location.href = "https://www.profotonet.com/nl"
                                                    } else if (this.state.languageCode === "en") {
                                                        location.href = "https://www.profotonet.com/en"
                                                    } else if (this.state.languageCode === "de") {
                                                        location.href = "https://www.profotonet.com/de"
                                                    } else if (this.state.languageCode === "fr") {
                                                        location.href = "https://www.profotonet.com/fr"
                                                    } else {
                                                        location.href = "https://www.profotonet.com/nl"
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* headerPhone */}
                                    <div className='headerPhoneHeader col-sm-1 col-md-4 align-self-center' style={{marginTop: "1px"}}>
                                        <img className='headerPhone' src="./assets/img/icons/phone.svg" alt="phone" width="16" style={{marginRight: "4px"}}/>
                                            <span className='headerPhone' style={{fontSize: ""}}>
                                                {
                                                    this.state.languageCode === "fr" ?
                                                        <span>
                                                            <span>{languageObject.QUESTION}     </span>
                                                            <a href="tel:+31 (0) 10-2640410" style={{textDecoration: "none", color: "#f08254", fontWeight: 700}}>
                                                                {languageObject.PHONENUMBER}
                                                            </a>
                                                            <span>{languageObject.AVAILABLE} {languageObject.TILL}</span>
                                                            <span style={{color: "#f08254", fontWeight: 700}}> {languageObject.TILLTIME} </span> 
                                                        </span>
                                                        :
                                                        <span>
                                                            <span>{languageObject.QUESTION} </span>
                                                            <a href="tel:010-26-40410" style={{textDecoration: "none", color: "#f08254", fontWeight: 700}}>
                                                                {languageObject.PHONENUMBER} 
                                                            </a>
                                                            <span> {languageObject.TILL}</span> 
                                                            <span style={{color: "#f08254", fontWeight: 700}}> {languageObject.TILLTIME} </span> 
                                                            <span>{languageObject.AVAILABLE}</span>
                                                        </span>
                                                }
                                            </span>
                                    </div>

                                    <div className=' col-6 col-sm-6 col-md-4 text-center align-self-end'>
                                        <div className='row  align-items-center'>

                                            {/* Configurator */}
                                            <div className='navbarImage col-md-1 ' style={{ borderBottom: (activeRoute === "/" || activeRoute === undefined ? "2px solid #617873" : "")}}>
                                                <a href="/" 
                                                    style = {{textDecoration: "none"}} 
                                                    onClick = {() => this.setState({showPopupItemAdded: false})}>

                                                        <div>
                                                            <img className='headerButton' src="./assets/img/icons/taopix-configurator-icon.svg" width="48" />
                                                        </div>
                                                        <span className='headerText' style={{color: "black", fontSize: "12px"}}>
                                                            {languageObject.CONFIGURATOR}
                                                        </span>
                                                </a>
                                            </div>

                                            {/* Mijn projecten  */}
                                            <div className='navbarImage col-md-1 ' style={{ borderBottom: (activeRoute === "/" + languageObject.PROJECTS.toLowerCase() ? "2px solid #617873" : "")}} > 
                                                <a href = {"/" + languageObject.route.PROJECTS} style = {{textDecoration: "none"}}>
                                                    <div className=''>
                                                        <img className='headerButton' src="./assets/img/icons/taopix-projects-icon.svg" width="48" />
                                                    </div>
                                                    <span className='headerText align-self-end' style={{fontSize: "12px", color: "black" }}>
                                                        {languageObject.MY_PROJECTS}
                                                    </span>
                                                </a>

                                            </div>

                                            {/* Mijn account */}
                                            <div className='navbarImage col-md-1' >
                                                {
                                                    isLoggedIn ?
                                                        <a href= {myAccountURL} style={{textDecoration: "none", cursor: "pointer"}} onClick={() => {
                                                        }}>
                                                            <div>
                                                                <img className='headerButton' src="./assets/img/icons/taopix-account-exit-icon.svg" width="48" style={{marginTop: "0px"}} />
                                                            </div>
                                                            <div>
                                                                <span className='headerText' style={{color: "black", fontSize: "12px"}}>{languageObject.MY_ACCOUNT}</span>
                                                            </div>
                                                        </a>
                                                    :
                                                        <a style={{textDecoration: "none", cursor: "pointer"}} onClick={() => {
                                                            TaopixService().signIn()
                                                        }}>
                                                            <div>
                                                                <img className='headerButton' src="./assets/img/icons/taopix-account-exit-icon.svg" width="48" style={{marginTop: "0px"}} />
                                                            </div>
                                                            <div>
                                                                <span className='headerText' style={{color: "black", fontSize: "12px"}}>{languageObject.MY_ACCOUNT}</span>
                                                            </div>
                                                        </a>
                                                }
                                            </div>

                                            {/* Winkelwagen */}
                                            <div className='navbarImage col-md-1' style={{position: "relative", borderBottom: (activeRoute === "/" + languageObject.CART.toLowerCase() ? "2px solid #617873" : "")}}>
                                                <a href = {"/" + languageObject.route.CART} style={{textDecoration: "none", textAlign: "center"}}>
                                                    <img className='headerButton' src='./assets/img/icons/taopix-cart-icon.svg' width="48" style={{marginTop: "0px"}} />
                                                    <span className="badgeBasket position-absolute badge rounded-pill bg-primary" style= {{left: "45%", top: "10%", bottom: "auto"}}>{this.state.basketItems}</span>
                                                    <div>
                                                        <span className='headerText align-self-end' style={{color: "black", fontSize: "12px" }}>{languageObject.CART}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <hr style={{marginTop: "0"}} />
                        
                        <div className='container'>

                            <Router onChange={() => {
                                _this.setState({
                                    activeRoute: getPathName()
                                });

                                if(!_this.state.testMode) {
                                    ReactTagManager.dataLayer({
                                        dataLayer: {
                                            event: 'Pageview',
                                            page: (window.location ? window.location.pathname : ""),
                                            pagePath: (window.location ? window.location.pathname : ""),
                                            title: (document && document.title ? document.title : ""),
                                            pageTitle: (document && document.title ? document.title : ""),
											language: languageCode
                                        }
                                    });
                                }
                                
                            }}>
                                <ConfiguratorContainer path="/" l={languageObject} testMode={this.state.testMode} languageCode={this.state.languageCode} isLoggedIn={isLoggedIn} showPopupItemAdded={showPopupItemAdded} parentCallback={this.handleCallback.bind(this)} nameGA={nameGA} nameFPLC={nameFPLC} namePFN_CID={namePFN_CID} />
                                <ProjectsContainer path={languageObject.route.PROJECTS} l={languageObject} testMode={this.state.testMode} languageCode={this.state.languageCode} isLoggedIn={isLoggedIn} maintenanceMode={this.state.maintenanceMode} />
                                <CartContainer path={languageObject.route.CART} l={languageObject} testMode={this.state.testMode} languageCode={this.state.languageCode} isLoggedIn={isLoggedIn} />
                                <AccountContainer path={languageObject.route.ACCOUNT} l={languageObject} testMode={this.state.testMode} languageCode={this.state.languageCode} isLoggedIn={isLoggedIn} />
                                <CartContainer default l={languageObject} testMode={this.state.testMode} languageCode={this.state.languageCode} />

                            </Router>

                        </div>

                        <div id="footer" className='footer container-fluid ' style={{backgroundColor: "#f2f5f4", maxHeight: "150px", paddingTop: "10px", paddingBottom: "10px", position: "fixed", bottom: "0px"}}>
                            <div className='container'>
                                <div className='row'>
                                    
                                    <div className='text-center' style={{fontSize: "13px"}}>
                                        © Profotonet 2022
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>
        )
    }
}
export default Container;