var language = {
    route: {
        PROJECTS: "projekte",
        CART: "warenkorb",
        ACCOUNT: "konto",
        MY_PROJECTS: "meine projekte",
    },
    configurator: {
        PHOTOBOOKS: "Fotobücher",
        CALENDERS: "Kalender",
        PREMIUM: "Premium",
        PREMIUM_PLUS: "Premium-Plus",
        PROFESSIONAL: "Professional",
        YEAR_CALENDER: "Jahreskalender",
        BIRTHDAY_CALENDER: "Geburtstagskalender",
        STANDING: "Stehen",
        LANDSCAPE: "Landschaft",
        SQUARE: "Quadrat",
        LEER_LINNEN_VINYL_COVER: "Fester Einband",
        FOTOCOVER_COVER: "Fotoabdeckung",
        LASERTEKST_COVER: "Per Laser eingraviertes Cover",
        PLEXIGLAS_COVER: "Abdeckung aus Plexiglas",
        FOTOCOVER_OP_LINNEN_COVER: "Fotocover auf Leinen",
        EMBOSSING_COVER: "Geprägter Einband",
        DELUXE_COOPER_COVER: "Deluxe Cooper",
        DELUXE_DELON_COVER: "Deluxe Delon",
        DELUXE_NEWMAN_COVER: "Deluxe Newman",
        DELUXE_FOTOCOVER_COVER: "Deluxe Fotocover",
        DELUXE_FRAME_LANDSCAPE_COVER: "Deluxe Frame Landscape",
        DELUXE_FRAME_DETAIL_COVER: "Deluxe Frame Detail",
        DELUXE_FRAME_TRIPLE_COVER: "Deluxe Frame Triple",
        DELUXE_FRAME_DELUXE_COVER: "Deluxe Frame Deluxe",
        BASIS_WIT_KAL: "Grundlegendes Weiß",
        BLOK_WIT_KAL: "Block weiß",
        CIRKEL_WIT_KAL: "Kreis weiß",
        TRANSPARANT_ZWART_KAL: "Transparentes Schwarz",
        BASIS_ZWART_KAL: "Grundlegendes Schwarz",
        VIERKANTE_FOTO_WIT_KAL: "Quadratisches Foto weiß",
        VIERKANTE_FOTO_ZWART_KAL: "Quadratisches Foto schwarz",
        TWEE_KOLOMMEN_KAL: "Zwei Spalten",
        KOLOM_KAL: "Spalte",
        BASIS_KAL: "Base",
        KOLOM_WIT_KAL: "Säule weiß",
        KOLOM_ZWART_KAL: "Säule schwarz",
        SOFTCOVER: "Softcover",
        FOTOCOVER: "Fotoabdeckung",
        HARDCOVER: "Hardcover"
    },
    PROJECTS: "Projekte",
    CART: "Warenkorb",
    MY_PROJECTS: "Meine Projekte",
    ACCOUNT: "account",
    MY_ACCOUNT: "Mein account",
    MY_CART: "mein Warenkorb",
    QUESTION: "Fragen?",
    PHONENUMBER: "010-2640410",
    TILL: "bis um",
    TILLTIME: "17:00",
    AVAILABLE: "zugänglich.",
    CONFIGURATOR: "Konfigurator",
    LOGIN: "Anmeldung",
    LOGOUT: "Ausloggen",
    TO_SEE_YOUR_PROJECTS: "um Ihre Projekte zu sehen",
    TO_SEE_YOUR_ACCOUNT: "um Ihr Konto zu sehen",
    NEW_PROJECT: "+ Neues Projekt",
    OPEN: "Öffnen",
    DUPLICATE: "Duplikat",
    CHOOSE_VERSION: "Eine Version wählen",
    CHOOSE_SIZE: "Eine Größe wählen",
    CHOOSE_ORIENTATION: "Eine Ausrichtung wählen",
    CHOSEN: "Gewählt:",
    CHOOSE_TYPE: "Typ auswählen",
    BACK: "Zurück",
    CHOOSE_KIND: "Art auswählen",
    FROM: "aus",
    TYPE: "Typ",
    LINE: "Produktpalette",
    ORIENTATION: "Ausrichtung",
    SIZE: "Abmessung",
    PRODUCT: "Produkt",

    CHOOSE_COVER: "Abdeckung wählen",
    
    MAKE_YOUR_PHOTOBOOK: "Gestalten Sie hochwertige Fotobücher mit unserer kostenlosen Software",
    DOWNLOAD_FOR_WINDOWS: "Für Windows herunterladen",
    DOWNLOAD_FOR_MAC: "Für Mac herunterladen",
    DIRECT_BEGINNEN: "Jetzt anfangen",
    ADJUST: "Anpassen",
    ORDER: "Bestellen",
    DOWNLOAD: "Download",
    MODIFY: "Ändern",
    CANCEL: "Absagen",
    YES: "Ja",
    NO: "Nein",
    DUTCH: "Nederlands",
    BRITISH: "English",
    FRENCH: "Français",
    GERMAN: "Deutsch",
    MADE_ON: "Gemacht auf: ",
    REVIEWS_CUSTOMERS: "Bewertungen 9,8 / 10 - 2907 kunden",
    DELIVERY_TIME: "Lieferzeit Fotobücher max. 7 tage",
    HOUSEHOLD_NAME: "Seit über 40 Jahren ein Begriff unter Fotografen",
    WINNER_QUALITY: "Gewinner des Fotobuchs in bester Qualität 2023",
    TO_SHOPPING_CART: "Zum Einkaufswagen",
    CLOSE: "Schließen",
    REMOVE_PROJECT_FROM_CART: "Möchtest du das Projekt aus dem Einkaufswagen entfernen?",
    PROJECT_ADDED_TO_CART: "Das Projekt wurde in den Warenkorb gelegt.",
    RENAME_THE_PROJECT: "Ändere den Namen des Projekts.",
    NEW_NAME_FOR_THE_PROJECT: "Gib einen neuen Namen für das Projekt ein.",
    DELETE_PROJECT: "Willst du das Projekt endgültig löschen?",
    COVER: "Cover",
    NOWHITELABEL: "mit profotonet Logo"
    
}

export default language;